// filename no more valid for this functionality but i'm to lazy for renaming it

export type CSSTransitionEasing = "ease" | "ease-in" | "ease-in-out" | "ease-out";

export const APP_TRANSITION_DURATION: number = 0.35;
export const APP_TRANSITION_PROPERTIES: string = "all";
export const APP_TRANSITION_EASING: CSSTransitionEasing = "ease-in-out";

export const getCSSTransition = (
  duration: number = APP_TRANSITION_DURATION,
  props: string = APP_TRANSITION_PROPERTIES,
  easing: CSSTransitionEasing = APP_TRANSITION_EASING
) => `${props} ${duration}s ${easing}`;
