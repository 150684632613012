import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import store from "./store";
import { storePersistor } from "./store/store";
import "./i18n";

import "./styles/style.scss";
import React from "react";

declare module "framer-motion" {
  interface AnimatePresenceProps {
    children?: React.ReactNode;
  }
}
declare module "@tiptap/extension-bubble-menu" {
  interface BubbleMenuPluginProps {
    children?: React.ReactNode;
  }
}
declare module "@tiptap/extension-floating-menu" {
  interface FloatingMenuPluginProps {
    children?: React.ReactNode;
  }
}

const container = document.getElementById("root");
const root = createRoot(container as NonNullable<HTMLElement>);

root.render(
  <Provider store={store}>
    <PersistGate loading='Loading Persisted Data' persistor={storePersistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
