import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getPersistConfig } from "redux-deep-persist";

import appReducer, { StoreAppName, StoreAppStateNames } from "./app";
import userReducer, { StoreUserName, StoreUserStateNames } from "./user";

const appPersistConfig = getPersistConfig({
  key: "userSettings",
  storage,
  whitelist: [StoreAppStateNames.Theme, `${StoreAppStateNames.Hints}.shown`],
  rootReducer: appReducer,
});

const userPersistConfig = {
  key: "userAccess",
  storage,
  whitelist: [StoreUserStateNames.Role],
};

export const StoreRootReducer = combineReducers({
  [StoreAppName]: persistReducer(appPersistConfig, appReducer),
  [StoreUserName]: persistReducer(userPersistConfig, userReducer),
});

const store = createStore(
  StoreRootReducer,
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk)
    : composeWithDevTools(applyMiddleware(thunk))
);

export const storePersistor = persistStore(store);

export default store;
