import React, { lazy, Suspense } from "react";
import { Route, useLocation, Routes, Navigate } from "react-router";
import { AnimatePresence } from "framer-motion";
import pMinDelay from "p-min-delay";

import AppSuspenseLoader from "./AppSuspenseLoader";

const AuthenticationPage = lazy(() =>
  pMinDelay(import("./pages/AuthenticationPage"), 500)
);

export const AuthenticationPageCodeQuery = "code";
export const AuthenticationPageTypeQuery = "t";
export const enum AuthenticationPageType {
  Login = "login",
  Recovery = "rec",
  RecoverySend = "rec-send",
  RecoveryApprove = "rec-approve",
  RegisterApprove = "reg-approve",
}

export const enum AuthenticationRoutingPaths {
  Auth = "/auth",
  AuthLogin = "/auth?t=login",
  AuthRecovery = "/auth?t=rec",
  AuthRecoverySend = "/auth?t=rec-send",
  AuthRecoveryApprove = "/auth?t=rec-approve",
  AuthRegisterApprove = "/auth?t=reg-approve",
}

const AuthenticationRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Suspense fallback={<AppSuspenseLoader isLoading />}>
        <Routes location={location} key={location.pathname}>
          <Route
            path={AuthenticationRoutingPaths.Auth}
            element={<AuthenticationPage />}
          />

          <Route path='*' element={<Navigate to={AuthenticationRoutingPaths.Auth} />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

export default AuthenticationRoutes;
