import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StoreRootDispatch } from "../../store";
import {
  selectAppModal,
  selectAppNotification,
  appNotificationClear,
  appModalOpen,
  appModalClose,
  StoreAppModalConfig,
  StoreAppModal,
} from "../../store/app";

const useAppModal = (): [
  isOpen: boolean,
  modal: StoreAppModal,
  onOpenModal: (modalData: StoreAppModalConfig) => void,
  onCloseModal: () => void
] => {
  const dispatch = useDispatch<StoreRootDispatch>();
  const modal = useSelector(selectAppModal);
  const notification = useSelector(selectAppNotification);

  // saved state is required so the modal content appears correct when it's closing
  const [saved, setSaved] = useState<StoreAppModal>(modal);
  useEffect(() => {
    if (modal) {
      setSaved(modal);
    } else if (!modal) {
      setTimeout(() => setSaved(null), 500);
    }
  }, [modal]);

  const onOpenModal = useCallback(
    (modalData: StoreAppModalConfig) => {
      if (!!notification && !modal) {
        dispatch(appNotificationClear());
      }
      dispatch(appModalOpen(modalData));
    },
    [dispatch, modal, notification]
  );
  const onCloseModal = useCallback(() => dispatch(appModalClose()), [dispatch]);

  return [!!modal, saved, onOpenModal, onCloseModal];
};

export default useAppModal;
