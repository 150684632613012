import { StoreActBase } from "..";
import { APIDataUser } from "../../services/APIService";
import { AppLanguages } from "../../shared/hooks/useAppTranslation";
import isJWTExpired from "../../shared/functions/isJWTExpired";

export const StoreUserName = "user";

export const enum StoreUserStateNames {
  Data = "data",
  Role = "role",
}

/**
 * ------------------------
 * User Data Settings
 * ------------------------
 */

export type StoreUserDataState = APIDataUser | null | undefined;

const localUser = localStorage.getItem("user");
const isLocalUser = !!localUser;
const localUserData = JSON.parse(isLocalUser ? (localUser as string) : "{}");
export const StoreUserDataDefault = isLocalUser
  ? isJWTExpired(localUserData.jwt)
    ? null
    : localUserData.user
  : null;
/**
 * ------------------------
 * User Role-Based Settings
 * ------------------------
 */

export const enum StoreUserRoleType {
  Creator = "creator",
  Manager = "manager",
  Admin = "admin",
}

export const StoreUserRoleMainBlockTabQuery = "mbt";
export const enum StoreUserRoleMainBlockTab {
  MyIdeas = "ideas",
  Inbox = "inbox",
  Requests = "requests",
}

export const enum StoreUserRoleSettingsTab {
  UserData = "user",
  Appearance = "appearance",
  CompanySpace = "company",
  Sharing = "share",
}

export const enum StoreUserRoleIdeasQueryType {
  PreviewRequest = "preview-req",
  PreviewManage = "preview-manage",
  Manage = "manage",
}

export interface StoreUserRoleState {
  type: StoreUserRoleType;
  mainTabs: StoreUserRoleMainBlockTab[];
  settingsTabs: StoreUserRoleSettingsTab[];
  queries: StoreUserRoleIdeasQueryType[];
}

export const StoreUserRoleTypeMap2State: Record<StoreUserRoleType, StoreUserRoleState> = {
  [StoreUserRoleType.Creator]: {
    type: StoreUserRoleType.Creator,
    mainTabs: [StoreUserRoleMainBlockTab.MyIdeas, StoreUserRoleMainBlockTab.Requests],
    settingsTabs: [
      StoreUserRoleSettingsTab.UserData,
      StoreUserRoleSettingsTab.Appearance,
    ],
    queries: [
      StoreUserRoleIdeasQueryType.PreviewManage,
      StoreUserRoleIdeasQueryType.PreviewRequest,
    ],
  },
  [StoreUserRoleType.Manager]: {
    type: StoreUserRoleType.Manager,
    mainTabs: [
      StoreUserRoleMainBlockTab.MyIdeas,
      StoreUserRoleMainBlockTab.Inbox,
      StoreUserRoleMainBlockTab.Requests,
    ],
    settingsTabs: [
      StoreUserRoleSettingsTab.UserData,
      StoreUserRoleSettingsTab.Appearance,
      StoreUserRoleSettingsTab.Sharing,
    ],
    queries: [
      StoreUserRoleIdeasQueryType.Manage,
      StoreUserRoleIdeasQueryType.PreviewManage,
      StoreUserRoleIdeasQueryType.PreviewRequest,
    ],
  },
  [StoreUserRoleType.Admin]: {
    type: StoreUserRoleType.Admin,
    mainTabs: [
      StoreUserRoleMainBlockTab.MyIdeas,
      StoreUserRoleMainBlockTab.Inbox,
      StoreUserRoleMainBlockTab.Requests,
    ],
    settingsTabs: [
      StoreUserRoleSettingsTab.UserData,
      StoreUserRoleSettingsTab.Appearance,
      StoreUserRoleSettingsTab.CompanySpace,
      StoreUserRoleSettingsTab.Sharing,
    ],
    queries: [
      StoreUserRoleIdeasQueryType.Manage,
      StoreUserRoleIdeasQueryType.PreviewManage,
      StoreUserRoleIdeasQueryType.PreviewRequest,
    ],
  },
};

export const StoreUserRoleStateDefault: StoreUserRoleState = {
  ...StoreUserRoleTypeMap2State[StoreUserRoleType.Creator],
};

/**
 * ------------------------
 * User Store Settings
 * ------------------------
 */

export type StoreUserState = {
  [StoreUserStateNames.Data]: StoreUserDataState;
  [StoreUserStateNames.Role]: StoreUserRoleState;
};

export const StoreUserStateDefault: StoreUserState = {
  [StoreUserStateNames.Data]: StoreUserDataDefault
    ? { ...StoreUserDataDefault }
    : StoreUserDataDefault,
  [StoreUserStateNames.Role]: { ...StoreUserRoleStateDefault },
};

/**
 * ------------------------
 * User Action Settings
 * ------------------------
 */

export const USER_LOGIN = "user/login";
export type StoreActUserLogin = StoreActBase<typeof USER_LOGIN, StoreUserDataState>;

export const USER_LOGOUT = "user/logout";
export type StoreActUserLogout = StoreActBase<typeof USER_LOGOUT>;

export const USER_CHANGE_ROLE = "user/change/role";
export type StoreActUserChangeRole = StoreActBase<
  typeof USER_CHANGE_ROLE,
  StoreUserRoleState
>;

export const USER_CHANGE_DATA = "user/change/data";
export type StoreActUserChangeData = StoreActBase<
  typeof USER_CHANGE_DATA,
  NonNullable<StoreUserDataState>
>;

export const USER_CHANGE_LOCALE = "user/change/locale";
export type StoreActUserChangeLocale = StoreActBase<
  typeof USER_CHANGE_LOCALE,
  AppLanguages
>;

export type StoreUserActs =
  | StoreActUserLogin
  | StoreActUserLogout
  | StoreActUserChangeRole
  | StoreActUserChangeData
  | StoreActUserChangeLocale;
