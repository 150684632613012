import React, { useMemo } from "react";
import { Typography, TypographyProps } from "@mui/material";

import useAppThemePalette from "../../../shared/hooks/useAppThemePalette";
import { getCSSTransition } from "../../../shared/functions/colorsMath";

export const enum TextFont {
  Roboto = "Roboto",
  Montserrat = "Montserrat",
  MontserratAlternates = "Montserrat Alternates",
}

export interface TextProps extends TypographyProps {
  font?: TextFont;
  isUpperCase?: boolean;
  isCapitalize?: boolean;
  isCenter?: boolean;
  isBold?: boolean;
  isExtraBold?: boolean;
  isBreakWord?: boolean;
  isHoverPointer?: boolean;
  isTextSelectable?: boolean;
}

const Text: React.FC<TextProps> = ({
  children,
  font,
  isUpperCase = false,
  isCapitalize = false,
  isCenter = false,
  isBold = false,
  isExtraBold = false,
  isBreakWord = false,
  isHoverPointer = false,
  isTextSelectable = false,
  className = "",
  variant = "subtitle1",
  fontWeight = 400,
  fontFamily = TextFont.Roboto,
  textAlign = "inherit",
  textTransform = "inherit",
  color = undefined,
  ...props
}) => {
  const palette = useAppThemePalette();

  const sx = useMemo<TypographyProps["sx"]>(() => {
    let _sx: TypographyProps["sx"] = {
      wordSpacing: "0",
      wordBreak: isBreakWord ? "break-word" : "inherit",
      userSelect: isTextSelectable ? "inherit" : "none",
      textDecoration: "none",
      cursor: "default",
      lineHeight: "130%",
      transition: getCSSTransition(),

      ":hover": {
        cursor: isHoverPointer ? "pointer" : "auto",
      },
    };

    if (typeof children === "string" && !children?.length) {
      _sx["display"] = "none";
    }

    return _sx;
  }, [children, isBreakWord, isHoverPointer, isTextSelectable]);

  return (
    <Typography
      color={color ?? palette.text.primary}
      variant={variant}
      textTransform={
        isUpperCase ? "uppercase" : isCapitalize ? "capitalize" : textTransform
      }
      textAlign={isCenter ? "center" : textAlign}
      fontFamily={font ?? fontFamily}
      fontWeight={isExtraBold ? 700 : isBold ? 500 : fontWeight}
      className={className}
      sx={sx}
      {...props}>
      {children}
    </Typography>
  );
};

export default Text;
