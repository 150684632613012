import { Color, createTheme, Theme, ThemeOptions } from "@mui/material";
import {
  StoreAppThemeAccentDarkHEX,
  StoreAppThemeAccentHEX,
  StoreAppThemeAccentLightHEX,
  StoreAppThemeAccentName,
  StoreAppThemeSchemaName,
} from "./store/app";

declare module "@mui/material/styles" {
  interface Palette {
    white: Color;
  }
  interface PaletteOptions {
    white?: Color;
  }
}

const defaultThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 530,
      md: 925,
      lg: 1270,
      xl: 1536,
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: "top",
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPaper: { defaultProps: { sx: { borderRadius: "12px" } } },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
        sx: {
          ":hover": {
            cursor: "pointer",
          },
        },
      },
    },
  },
  typography: {
    button: { fontSize: "16px" },
    body1: { fontSize: "20px" },
    body2: { fontSize: "28px" },
    subtitle1: { fontSize: "16px" },
    subtitle2: { fontSize: "18px" },
    caption: { fontSize: "14px" },
    h4: { fontSize: "32px" },
    h3: { fontSize: "48px" },
  },
};

const _schemaPalette: Record<StoreAppThemeSchemaName, ThemeOptions["palette"]> = {
  [StoreAppThemeSchemaName.Light]: {
    mode: "light",
    error: {
      main: StoreAppThemeAccentHEX.Strawberry,
    },
    success: {
      main: StoreAppThemeAccentHEX.Mint,
    },
    warning: {
      main: StoreAppThemeAccentHEX.Banana,
    },
    info: {
      main: StoreAppThemeAccentHEX.Curacao,
    },
    secondary: { main: "rgba(255,255,255,0.9)", dark: "rgba(255,255,255,0.8)" },
    common: {
      white: "#fff",
      black: "#000",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.8)",
      secondary: "rgba(0, 0, 0, 0.5)",
      disabled: "rgba(0, 0, 0, 0.1)",
    },
    divider: "rgba(255, 255, 255, 0.5)",
    grey: {
      "50": "rgba(0, 0, 0, 0.05)",
      "100": "rgba(0, 0, 0, 0.15)",
      "200": "rgba(0, 0, 0, 0.25)",
      "300": "rgba(0, 0, 0, 0.35)",
      "400": "rgba(0, 0, 0, 0.45)",
      "500": "rgba(0, 0, 0, 0.5)",
      "600": "rgba(0, 0, 0, 0.6)",
      "700": "rgba(0, 0, 0, 0.7)",
      "800": "rgba(0, 0, 0, 0.8)",
      "900": "rgba(0, 0, 0, 0.9)",
      A100: "rgba(0, 0, 0, 0.15)",
      A200: "rgba(0, 0, 0, 0.5)",
      A400: "rgba(0, 0, 0, 0.8)",
      A700: "#000",
    },
    white: {
      "50": "rgba(255, 255, 255, 0.05)",
      "100": "rgba(255, 255, 255, 0.15)",
      "200": "rgba(255, 255, 255, 0.25)",
      "300": "rgba(255, 255, 255, 0.35)",
      "400": "rgba(255, 255, 255, 0.45)",
      "500": "rgba(255, 255, 255, 0.5)",
      "600": "rgba(255, 255, 255, 0.6)",
      "700": "rgba(255, 255, 255, 0.7)",
      "800": "rgba(255, 255, 255, 0.8)",
      "900": "rgba(255, 255, 255, 0.9)",
      A100: "rgba(255, 255, 255, 0.15)",
      A200: "rgba(255, 255, 255, 0.5)",
      A400: "rgba(255, 255, 255, 0.8)",
      A700: "#fff",
    },
  },
  [StoreAppThemeSchemaName.Dark]: {
    mode: "dark",
    error: {
      main: StoreAppThemeAccentHEX.Strawberry,
    },
    success: {
      main: StoreAppThemeAccentHEX.Mint,
    },
    warning: {
      main: StoreAppThemeAccentHEX.Banana,
    },
    info: {
      main: StoreAppThemeAccentHEX.Curacao,
    },
    secondary: { main: "rgba(0,0,0,0.9)", dark: "rgba(0,0,0,0.8)" },
    common: {
      white: "#000",
      black: "#fff",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.8)",
      secondary: "rgba(255, 255, 255, 0.5)",
      disabled: "rgba(255, 255, 255, 0.1)",
    },
    divider: "rgba(0, 0, 0, 0.5)",
    grey: {
      "50": "rgba(255, 255, 255, 0.05)",
      "100": "rgba(255, 255, 255, 0.15)",
      "200": "rgba(255, 255, 255, 0.25)",
      "300": "rgba(255, 255, 255, 0.35)",
      "400": "rgba(255, 255, 255, 0.45)",
      "500": "rgba(255, 255, 255, 0.5)",
      "600": "rgba(255, 255, 255, 0.6)",
      "700": "rgba(255, 255, 255, 0.7)",
      "800": "rgba(255, 255, 255, 0.8)",
      "900": "rgba(255, 255, 255, 0.9)",
      A100: "rgba(0, 0, 0, 0.15)",
      A200: "rgba(0, 0, 0, 0.5)",
      A400: "rgba(0, 0, 0, 0.8)",
      A700: "#000",
    },
    white: {
      "50": "rgba(0, 0, 0, 0.05)",
      "100": "rgba(0, 0, 0, 0.15)",
      "200": "rgba(0, 0, 0, 0.25)",
      "300": "rgba(0, 0, 0, 0.35)",
      "400": "rgba(0, 0, 0, 0.45)",
      "500": "rgba(0, 0, 0, 0.5)",
      "600": "rgba(0, 0, 0, 0.6)",
      "700": "rgba(0, 0, 0, 0.7)",
      "800": "rgba(0, 0, 0, 0.8)",
      "900": "rgba(0, 0, 0, 0.9)",
      A100: "rgba(255, 255, 255, 0.15)",
      A200: "rgba(255, 255, 255, 0.5)",
      A400: "rgba(255, 255, 255, 0.8)",
      A700: "#fff",
    },
  },
  [StoreAppThemeSchemaName.ReadingModeDark]: {
    mode: "dark",
    error: {
      main: StoreAppThemeAccentHEX.Lotus,
    },
    success: {
      main: StoreAppThemeAccentHEX.Amazon,
    },
    warning: {
      main: StoreAppThemeAccentHEX.Iroko,
    },
    info: {
      main: StoreAppThemeAccentHEX.Firefly,
    },
    secondary: { main: "rgba(30, 20, 10, 0.9)", dark: "rgba(30, 20, 10, 0.8)" },
    common: {
      white: "#10100f",
      black: "#bdb2a4",
    },
    text: {
      primary: "rgba(204, 192, 177, 0.8)",
      secondary: "rgba(204, 192, 177, 0.5)",
      disabled: "rgba(204, 192, 177, 0.1)",
    },
    divider: "rgba(128, 125, 121, 0.5)",
    grey: {
      "50": "rgba(128, 125, 121, 0.05)",
      "100": "rgba(128, 125, 121, 0.15)",
      "200": "rgba(128, 125, 121, 0.25)",
      "300": "rgba(128, 125, 121, 0.35)",
      "400": "rgba(128, 125, 121, 0.45)",
      "500": "rgba(128, 125, 121, 0.5)",
      "600": "rgba(128, 125, 121, 0.6)",
      "700": "rgba(128, 125, 121, 0.7)",
      "800": "rgba(128, 125, 121, 0.8)",
      "900": "rgba(128, 125, 121, 0.9)",
      A100: "rgba(16, 16, 15, 0.15)",
      A200: "rgba(16, 16, 15, 0.5)",
      A400: "rgba(16, 16, 15, 0.8)",
      A700: "#10100f",
    },
    white: {
      "50": "rgba(189, 178, 164, 0.05)",
      "100": "rgba(189, 178, 164, 0.15)",
      "200": "rgba(189, 178, 164, 0.25)",
      "300": "rgba(189, 178, 164, 0.35)",
      "400": "rgba(189, 178, 164, 0.45)",
      "500": "rgba(189, 178, 164, 0.5)",
      "600": "rgba(189, 178, 164, 0.6)",
      "700": "rgba(189, 178, 164, 0.7)",
      "800": "rgba(189, 178, 164, 0.8)",
      "900": "rgba(189, 178, 164, 0.9)",
      A100: "rgba(189, 178, 164, 0.15)",
      A200: "rgba(189, 178, 164, 0.5)",
      A400: "rgba(189, 178, 164, 0.8)",
      A700: "#bdb2a4",
    },
  },
  [StoreAppThemeSchemaName.ReadingModeLight]: {
    mode: "light",
    error: {
      main: StoreAppThemeAccentHEX.Lotus,
    },
    success: {
      main: StoreAppThemeAccentHEX.Amazon,
    },
    warning: {
      main: StoreAppThemeAccentHEX.Iroko,
    },
    info: {
      main: StoreAppThemeAccentHEX.Firefly,
    },
    secondary: { main: "rgba(189, 178, 164, 0.9)", dark: "rgba(189, 178, 164, 0.8)" },
    common: {
      white: "#bdb2a4",
      black: "#10100f",
    },
    text: {
      primary: "rgba(36, 31, 27, 0.8)",
      secondary: "rgba(36, 31, 27, 0.5)",
      disabled: "rgba(36, 31, 27, 0.1)",
    },
    divider: "rgba(128, 125, 121, 0.5)",
    grey: {
      "50": "rgba(36, 31, 27, 0.05)",
      "100": "rgba(36, 31, 27, 0.15)",
      "200": "rgba(36, 31, 27, 0.25)",
      "300": "rgba(36, 31, 27, 0.35)",
      "400": "rgba(36, 31, 27, 0.45)",
      "500": "rgba(36, 31, 27, 0.5)",
      "600": "rgba(36, 31, 27, 0.6)",
      "700": "rgba(36, 31, 27, 0.7)",
      "800": "rgba(36, 31, 27, 0.8)",
      "900": "rgba(36, 31, 27, 0.9)",
      A100: "rgba(16, 16, 15, 0.15)",
      A200: "rgba(16, 16, 15, 0.5)",
      A400: "rgba(16, 16, 15, 0.8)",
      A700: "#10100f",
    },
    white: {
      "50": "rgba(189, 178, 164, 0.05)",
      "100": "rgba(189, 178, 164, 0.15)",
      "200": "rgba(189, 178, 164, 0.25)",
      "300": "rgba(189, 178, 164, 0.35)",
      "400": "rgba(189, 178, 164, 0.45)",
      "500": "rgba(189, 178, 164, 0.5)",
      "600": "rgba(189, 178, 164, 0.6)",
      "700": "rgba(189, 178, 164, 0.7)",
      "800": "rgba(189, 178, 164, 0.8)",
      "900": "rgba(189, 178, 164, 0.9)",
      A100: "rgba(189, 178, 164, 0.15)",
      A200: "rgba(189, 178, 164, 0.5)",
      A400: "rgba(189, 178, 164, 0.8)",
      A700: "#bdb2a4",
    },
  },
};

const _accentPalette: Record<StoreAppThemeAccentName, ThemeOptions["palette"]> = {
  [StoreAppThemeAccentName.Banana]: {
    primary: {
      main: StoreAppThemeAccentHEX.Banana,
      dark: StoreAppThemeAccentDarkHEX.Banana,
    },
  },
  [StoreAppThemeAccentName.Tangerine]: {
    primary: {
      main: StoreAppThemeAccentHEX.Tangerine,
      dark: StoreAppThemeAccentDarkHEX.Tangerine,
    },
  },
  [StoreAppThemeAccentName.Strawberry]: {
    primary: {
      main: StoreAppThemeAccentHEX.Strawberry,
      dark: StoreAppThemeAccentDarkHEX.Strawberry,
    },
  },
  [StoreAppThemeAccentName.Lime]: {
    primary: {
      main: StoreAppThemeAccentHEX.Lime,
      dark: StoreAppThemeAccentDarkHEX.Lime,
    },
  },
  [StoreAppThemeAccentName.Tarragon]: {
    primary: {
      main: StoreAppThemeAccentHEX.Tarragon,
      dark: StoreAppThemeAccentDarkHEX.Tarragon,
    },
  },
  [StoreAppThemeAccentName.Mint]: {
    primary: {
      main: StoreAppThemeAccentHEX.Mint,
      dark: StoreAppThemeAccentDarkHEX.Mint,
    },
  },
  [StoreAppThemeAccentName.Curacao]: {
    primary: {
      main: StoreAppThemeAccentHEX.Curacao,
      dark: StoreAppThemeAccentDarkHEX.Curacao,
    },
  },
  [StoreAppThemeAccentName.Blueberry]: {
    primary: {
      main: StoreAppThemeAccentHEX.Blueberry,
      dark: StoreAppThemeAccentDarkHEX.Blueberry,
    },
  },
  [StoreAppThemeAccentName.Violet]: {
    primary: {
      main: StoreAppThemeAccentHEX.Violet,
      dark: StoreAppThemeAccentDarkHEX.Violet,
    },
  },
  [StoreAppThemeAccentName.Raspberry]: {
    primary: {
      main: StoreAppThemeAccentHEX.Raspberry,
      dark: StoreAppThemeAccentDarkHEX.Raspberry,
    },
  },
  [StoreAppThemeAccentName.Gray]: {
    primary: {
      main: StoreAppThemeAccentHEX.Gray,
      dark: StoreAppThemeAccentDarkHEX.Gray,
    },
  },

  [StoreAppThemeAccentName.Iroko]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Iroko,
      main: StoreAppThemeAccentHEX.Iroko,
      dark: StoreAppThemeAccentDarkHEX.Iroko,
    },
  },
  [StoreAppThemeAccentName.Pesto]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Pesto,
      main: StoreAppThemeAccentHEX.Pesto,
      dark: StoreAppThemeAccentDarkHEX.Pesto,
    },
  },
  [StoreAppThemeAccentName.Woodland]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Woodland,
      main: StoreAppThemeAccentHEX.Woodland,
      dark: StoreAppThemeAccentDarkHEX.Woodland,
    },
  },
  [StoreAppThemeAccentName.Everglade]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Everglade,
      main: StoreAppThemeAccentHEX.Everglade,
      dark: StoreAppThemeAccentDarkHEX.Everglade,
    },
  },
  [StoreAppThemeAccentName.Amazon]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Amazon,
      main: StoreAppThemeAccentHEX.Amazon,
      dark: StoreAppThemeAccentDarkHEX.Amazon,
    },
  },
  [StoreAppThemeAccentName.Spectra]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Spectra,
      main: StoreAppThemeAccentHEX.Spectra,
      dark: StoreAppThemeAccentDarkHEX.Spectra,
    },
  },
  [StoreAppThemeAccentName.Firefly]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Firefly,
      main: StoreAppThemeAccentHEX.Firefly,
      dark: StoreAppThemeAccentDarkHEX.Firefly,
    },
  },
  [StoreAppThemeAccentName.Lynch]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Lynch,
      main: StoreAppThemeAccentHEX.Lynch,
      dark: StoreAppThemeAccentDarkHEX.Lynch,
    },
  },
  [StoreAppThemeAccentName.Eminence]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Eminence,
      main: StoreAppThemeAccentHEX.Eminence,
      dark: StoreAppThemeAccentDarkHEX.Eminence,
    },
  },
  [StoreAppThemeAccentName.Grape]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Grape,
      main: StoreAppThemeAccentHEX.Grape,
      dark: StoreAppThemeAccentDarkHEX.Grape,
    },
  },

  [StoreAppThemeAccentName.Cosmic]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Cosmic,
      main: StoreAppThemeAccentHEX.Cosmic,
      dark: StoreAppThemeAccentDarkHEX.Cosmic,
    },
  },
  [StoreAppThemeAccentName.Lotus]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Lotus,
      main: StoreAppThemeAccentHEX.Lotus,
      dark: StoreAppThemeAccentDarkHEX.Lotus,
    },
  },
  [StoreAppThemeAccentName.Concord]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Concord,
      main: StoreAppThemeAccentHEX.Concord,
      dark: StoreAppThemeAccentDarkHEX.Concord,
    },
  },
  [StoreAppThemeAccentName.Alto]: {
    primary: {
      light: StoreAppThemeAccentLightHEX.Alto,
      main: StoreAppThemeAccentHEX.Alto,
      dark: StoreAppThemeAccentDarkHEX.Alto,
    },
  },
};

export const getAppThemeOptions = (
  schema: StoreAppThemeSchemaName,
  accent: StoreAppThemeAccentName
): Theme => {
  const sp = _schemaPalette[schema];
  const ap = _accentPalette[accent];

  return createTheme({
    ...defaultThemeOptions,
    palette: { ...sp, ...ap },
  });
};

export default getAppThemeOptions;
