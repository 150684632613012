import React, { CSSProperties, MouseEventHandler, ReactNode, useMemo } from "react";
import styled from "@emotion/styled";
import { alpha } from "@mui/material";

import useAppThemePalette from "../../../shared/hooks/useAppThemePalette";
import { getCSSTransition } from "../../../shared/functions/colorsMath";

type ButtonBtnTypeProp = "submit" | "reset" | "button";

export const enum ButtonType {
  Primary = 0,
  Grey,
  White,
  Warning,
}

export type ButtonOnClickHandler = MouseEventHandler<
  HTMLButtonElement & { _name?: string }
>;

interface ButtonColorState {
  default: CSSProperties["background"];
  active: CSSProperties["background"];
  hover: CSSProperties["background"];
  disabled?: CSSProperties["background"];
}

interface ButtonStyledProps {
  isTool: boolean;
  isOutline: boolean;
  isActive: boolean;
  isDisabled: boolean;
  borderRadius: CSSProperties["borderRadius"];
  background: ButtonColorState;
  width?: CSSProperties["width"];
}
const ButtonStyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
const ButtonStyled = styled.button<ButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  outline: none;
  border: none;
  height: auto;
  gap: 8px;

  transition: ${getCSSTransition()};
  width: ${({ width }) => width ?? "auto"};
  padding: ${({ isTool }) => (isTool ? "8px 20px" : "17px 32px")};
  margin: 0px;
  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ background, isOutline }) =>
    isOutline ? `2px solid ${background.default}` : "none"};
  background: ${({ background, isOutline, isActive, isDisabled }) =>
    isOutline
      ? "none"
      : isDisabled && background.disabled
      ? background.disabled
      : isActive
      ? background.active
      : background.default};

  &:hover {
    background: ${({ background }) => background.hover};
  }
  &:active {
    background: ${({ background }) => background.active};
  }
`;

export interface ButtonProps {
  btnType?: ButtonBtnTypeProp;
  type?: ButtonType;
  children?: ReactNode;
  isTool?: boolean;
  onClick?: ButtonOnClickHandler;
  isOnClickReset?: boolean;
  disabled?: boolean;
  className?: string;
  width?: CSSProperties["width"];
  style?: CSSProperties;
  isRightRounded?: boolean;
  isLeftRounded?: boolean;
  isActive?: boolean;
  isActiveInvert?: boolean;
  isOutline?: boolean;
  name?: string;
}

const Button: React.FC<ButtonProps> = ({
  btnType = "button",
  type = ButtonType.White,
  children,
  isTool,
  onClick,
  isOnClickReset = false,
  disabled,
  className = "",
  width,
  style = {},
  isOutline = false,
  isRightRounded = true,
  isLeftRounded = true,
  isActive = false,
  name = "btn",
}) => {
  const palette = useAppThemePalette();

  const _type = useMemo(() => type ?? ButtonType.White, [type]);

  const _roundRadius = useMemo(() => (isTool ? "8px" : "12px"), [isTool]);
  const _rounds = useMemo(
    () => ({
      left: {
        top: !isLeftRounded ? "0" : _roundRadius,
        bottom: !isLeftRounded ? "0" : _roundRadius,
      },
      right: {
        top: !isRightRounded ? "0" : _roundRadius,
        bottom: !isRightRounded ? "0" : _roundRadius,
      },
    }),
    [_roundRadius, isLeftRounded, isRightRounded]
  );

  const _themed = useMemo<
    Record<ButtonType, { color?: CSSProperties["color"]; background: ButtonColorState }>
  >(
    () => ({
      [ButtonType.Primary]: {
        color: disabled ? palette.grey[600] : "",
        background: {
          active: alpha(palette.primary.main, 0.9),
          hover: alpha(palette.primary.main, 0.7),
          default: palette.primary.main,
          disabled: palette.grey[200],
        },
      },
      [ButtonType.Grey]: {
        color: "",
        background: {
          active: palette.grey[300],
          hover: palette.grey[100],
          default: palette.grey[50],
          disabled: palette.grey[100],
        },
      },
      [ButtonType.White]: {
        background: {
          active: palette.grey[100],
          hover: palette.grey[50],
          default: palette.common.white,
          disabled: palette.grey[100],
        },
      },
      [ButtonType.Warning]: {
        background: {
          active: alpha(palette.error.main, 0.5),
          hover: alpha(palette.error.main, 0.15),
          default: alpha(palette.error.main, 0.05),
          disabled: alpha(palette.error.main, 0.15),
        },
      },
    }),
    [
      disabled,
      palette.common.white,
      palette.error.main,
      palette.grey,
      palette.primary.main,
    ]
  );
  const _themedProps = useMemo(() => _themed[_type], [_themed, _type]);

  return (
    <ButtonStyledWrapper className={className ?? ""}>
      <ButtonStyled
        isDisabled={!!disabled}
        isActive={isActive}
        isOutline={isOutline}
        isTool={isTool ?? false}
        borderRadius={`${_rounds.left.top} ${_rounds.right.top} ${_rounds.right.bottom} ${_rounds.left.bottom}`}
        background={_themedProps.background}
        className={`${className ?? ""} ${onClick ? "on-hover" : ""}`}
        style={style ?? {}}
        width={width}
        name={name}
        type={btnType}
        disabled={disabled}
        onClick={(e) => {
          isOnClickReset && e?.preventDefault();
          if (onClick) {
            onClick({ ...e, currentTarget: { ...e.currentTarget, _name: name ?? "" } });
          }
        }}>
        {children}
      </ButtonStyled>
    </ButtonStyledWrapper>
  );
};

export default Button;
