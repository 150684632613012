import React, { CSSProperties, useMemo } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

import { onClickHandler } from "../types";
import useAppThemePalette from "../../../shared/hooks/useAppThemePalette";
import { getCSSTransition } from "../../../shared/functions/colorsMath";

interface BlockStyledProps {
  isThin: boolean;
  boxShadow: CSSProperties["boxShadow"];
  height: CSSProperties["height"];
  minHeight: CSSProperties["minHeight"];
  maxHeight: CSSProperties["maxHeight"];
  width: CSSProperties["width"];
  minWidth: CSSProperties["minWidth"];
  maxWidth: CSSProperties["maxWidth"];
  size: CSSProperties["minWidth"];
  background: string;
}
const BlockStyled = styled(Box)<BlockStyledProps>(
  ({
    boxShadow,
    isThin,
    size,
    height,
    minHeight,
    maxHeight,
    width,
    minWidth,
    maxWidth,
    background,
  }) => `
  display: flex;
  border-radius: 12px;

  box-shadow: ${boxShadow};
  padding: ${isThin ? "24px 24px" : "36px 24px"};
  height: ${size ?? height};
  min-height: ${size ?? minHeight};
  max-height: ${size ?? maxHeight};
  width: ${size ?? width};
  min-width: ${size ?? minWidth};
  max-width: ${size ?? maxWidth};
  background: ${background};
  transition: ${getCSSTransition(0.35, "background")};
  background-repeat: repeat;
`
);

interface BlockProps extends Partial<Omit<BlockStyledProps, "isPaused">> {
  onClick?: onClickHandler;
  className?: string;
  children?: React.ReactNode;
  isAccent?: boolean;
  isShadow?: boolean;
  isSoftShadow?: boolean;
  style?: CSSProperties;
  blockRef?: React.RefObject<HTMLDivElement> | null;
}

export const BlockDefaultWidth = "79.203%";

const Block: React.FC<BlockProps> = ({
  onClick = () => {},
  className = "",
  children,
  isThin = false,
  isShadow = true,
  isSoftShadow = false,
  isAccent = false,
  style = {},
  height = "auto",
  minHeight = "auto",
  maxHeight = "auto",
  width = BlockDefaultWidth,
  minWidth = "auto",
  maxWidth = "auto",
  background,
  size,
  blockRef,
}) => {
  const palette = useAppThemePalette();

  const boxShadow = useMemo(() => {
    if (!isShadow) {
      return "";
    }

    return `${isSoftShadow ? "0 4px 20px" : "0 10px 10px"} ${palette.text.disabled}`;
  }, [isShadow, isSoftShadow, palette.text.disabled]);

  const blockBg = useMemo(
    () =>
      typeof background === "string"
        ? background
        : isAccent
        ? `linear-gradient(106.42deg, ${palette.primary.main}, ${palette.primary.dark})`
        : palette.common.white,
    [
      background,
      isAccent,
      palette.common.white,
      palette.primary.dark,
      palette.primary.main,
    ]
  );

  return (
    <BlockStyled
      background={blockBg}
      isThin={isThin}
      boxShadow={boxShadow}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      size={size}
      className={`${className ? className : ""}`}
      onClick={(e) => (onClick ? onClick(e) : 0)}
      style={style ?? {}}
      ref={blockRef}>
      {children}
    </BlockStyled>
  );
};

export default Block;
