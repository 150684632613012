import React, { CSSProperties, useMemo } from "react";

interface SpacerProps {
  x?: string;
  y?: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  style?: CSSProperties;
  onClick?: () => void;
  className?: string;
}

const Spacer: React.FC<SpacerProps> = ({
  x,
  y,
  left,
  right,
  top,
  bottom,
  style = {},
  onClick,
  className = "",
}) => {
  const _margins = useMemo(
    () => ({
      mt: top ? top : y ? y : 0,
      mr: right ? right : x ? x : 0,
      mb: bottom ? bottom : y ? y : 0,
      ml: left ? left : x ? x : 0,
    }),
    [bottom, left, right, top, x, y]
  );

  const styles = useMemo(
    () => ({
      margin: `${_margins.mt} ${_margins.mr} ${_margins.mb} ${_margins.ml}`,
      ...style,
    }),
    [_margins, style]
  );

  return (
    <div
      className={`${className ?? ""}`}
      style={styles}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
    />
  );
};

export default Spacer;
