import React, { lazy, Suspense, useEffect, useMemo } from "react";
import { Route, useLocation, Routes, Navigate } from "react-router";
import { AnimatePresence } from "framer-motion";
import pMinDelay from "p-min-delay";

import AppSuspenseLoader from "./AppSuspenseLoader";
import useDocumentTitle from "./shared/hooks/useDocumentTitle";
import useAppTranslation, { AppTranslationText } from "./shared/hooks/useAppTranslation";

const HomePage = lazy(() => pMinDelay(import("./pages/HomePage"), 500));
const SettingsPage = lazy(() => pMinDelay(import("./pages/SettingsPage"), 500));
const EditorPage = lazy(() => pMinDelay(import("./pages/EditorPage"), 500));

export const enum AppRoutingPaths {
  Ideas = "/ideas",
  Settings = "/settings",
  IdeaEditor = "/editor",
}

const AppRoutes: React.FC = () => {
  const location = useLocation();
  const [t] = useAppTranslation();
  const [, changeTitle] = useDocumentTitle();

  const _appRoute2Title = useMemo<Record<AppRoutingPaths, string>>(
    () => ({
      [AppRoutingPaths.Ideas]: t(AppTranslationText.IdeasTabsMyIdeas),
      [AppRoutingPaths.Settings]: t(AppTranslationText.SettingsPageTitle),
      [AppRoutingPaths.IdeaEditor]: t(AppTranslationText.EditorPageTitle),
    }),
    [t]
  );

  useEffect(() => {
    changeTitle(_appRoute2Title[location.pathname as AppRoutingPaths]);
  }, [_appRoute2Title, changeTitle, location.pathname]);

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Suspense fallback={<AppSuspenseLoader />} key={location.pathname}>
        <Routes location={location} key={location.pathname}>
          <Route path={AppRoutingPaths.Ideas} element={<HomePage />} />
          <Route path={AppRoutingPaths.Settings} element={<SettingsPage />} />
          <Route path={AppRoutingPaths.IdeaEditor} element={<EditorPage />} />

          <Route path='*' element={<Navigate to={AppRoutingPaths.Ideas} />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

export default AppRoutes;
