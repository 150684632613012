import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HintProps } from "../../components/Common/Hint";
import APIService, { APIServicePaths } from "../../services/APIService";
import {
  appHintsQueueUpdate,
  appHintsUpdate,
  selectAppHints,
  StoreAppHintsConfig,
  StoreAppHintType,
} from "../../store/app";
import useAppLoading from "./useAppLoading";
import useAppNotification from "./useAppNotifications";
import { AppTranslationText } from "./useAppTranslation";

export interface AppHintsState {
  currentHint: HintProps | null;
  hintAdd: (hints: HintProps[]) => void;
  hintDel: (hint: StoreAppHintType) => Promise<void>;
  isHintsActive: boolean;
  hintsState: StoreAppHintsConfig;
}

const useAppHints = (): AppHintsState => {
  const dispatch = useDispatch();
  const hintsState = useSelector(selectAppHints);
  const [, notificationSet] = useAppNotification();
  const [, startPendingRequest, stopPendingRequest] = useAppLoading();

  const _getFirstHintInQueue = useCallback(
    (q: HintProps[]): HintProps | null => (q.length ? q[0] : null),
    []
  );

  const [currentHint, setCurrentHint] = useState<HintProps | null>(
    _getFirstHintInQueue(hintsState.queue)
  );

  useEffect(() => {
    setCurrentHint(_getFirstHintInQueue(hintsState.queue));
  }, [hintsState.queue, _getFirstHintInQueue]);

  const hintAdd = useCallback(
    (hints: HintProps[]) => {
      let _newQueue = [...hintsState.queue];

      for (const hc of hints) {
        const hintConfig = hc;
        const isHintAlreadyShown = hintsState.shown[hintConfig.type];
        const isHintAlreadyInQueue = !!hintsState.queue.find(
          (qh) => qh.type === hintConfig.type
        );

        if (!isHintAlreadyShown && !isHintAlreadyInQueue) {
          _newQueue = [..._newQueue, hintConfig];
        }
      }

      if (_newQueue.length !== hintsState.queue.length) {
        dispatch(appHintsQueueUpdate(_newQueue));
      }
    },
    [dispatch, hintsState]
  );

  const hintDel = useCallback(
    async (hint: StoreAppHintType) => {
      const _newQueue = [...hintsState.queue];
      const _hintIdx = _newQueue.findIndex((q) => q.type === hint);

      if (-1 !== _hintIdx) {
        startPendingRequest();

        const resp = await APIService.postHandler(
          APIServicePaths.HintsShown,
          { name: hint },
          () => {
            notificationSet({ msg: t(AppTranslationText.CommonErrorUnknown) });
            stopPendingRequest();
          }
        );

        if (resp) {
          _newQueue.splice(_hintIdx, 1);
          dispatch(
            appHintsUpdate({
              queue: _newQueue,
              shown: { ...hintsState.shown, [hint]: true },
            })
          );
        }

        stopPendingRequest();
      }
    },
    [
      dispatch,
      hintsState.queue,
      hintsState.shown,
      notificationSet,
      startPendingRequest,
      stopPendingRequest,
    ]
  );

  const hintsRetData: AppHintsState = useMemo(
    () => ({
      currentHint,
      hintAdd,
      hintDel,
      isHintsActive: !!currentHint,
      hintsState,
    }),
    [currentHint, hintAdd, hintDel, hintsState]
  );
  return hintsRetData;
};

export default useAppHints;
