import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { appLoadingStart, appLoadingStop, selectAppLoading } from "../../store/app";

interface AppLoadingConfig {
  stopDelay: number; // after this delay the loading will actually stops
}

const _defaultAppLoadingConfig: AppLoadingConfig = {
  stopDelay: 350,
};

const useAppLoading = (
  cfg: Partial<AppLoadingConfig> = {}
): [isLoading: boolean, startLoading: () => void, stopLoading: () => void] => {
  const _cfg = useMemo(() => ({ ...cfg, ..._defaultAppLoadingConfig }), [cfg]);

  const dispatch = useDispatch();
  const loadingState = useSelector(selectAppLoading);

  const startLoading = useCallback(() => {
    dispatch(appLoadingStart());
  }, [dispatch]);

  const stopLoading = useCallback(() => {
    setTimeout(() => {
      dispatch(appLoadingStop());
    }, _cfg.stopDelay);
  }, [dispatch, _cfg.stopDelay]);

  return [loadingState, startLoading, stopLoading];
};

export default useAppLoading;
