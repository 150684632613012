import React, { CSSProperties, useMemo } from "react";
import styled from "@emotion/styled";
import { alpha, Theme } from "@mui/material";

import useAdaptiveLayout from "../../../shared/hooks/useAdaptiveLayout";
import useAppLoading from "../../../shared/hooks/useAppLoading";
import useAppThemePalette from "../../../shared/hooks/useAppThemePalette";
import useAppTranslation, {
  AppTranslationText,
} from "../../../shared/hooks/useAppTranslation";
import { StoreAppHintType } from "../../../store/app";

import Block from "../Block";
import Button, { ButtonType } from "../Button";
import Spacer from "../Spacer";
import Text from "../Text";

interface HintWrapperProps {
  isDimmedBackground: boolean;
}

const HintWrapper = styled.div<HintWrapperProps>(
  ({ theme, isDimmedBackground }) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9001;
  overflow: hidden;
  background: ${
    isDimmedBackground ? alpha((theme as Theme).palette.common.white, 0.8) : "none"
  };
`
);

interface HintRendererProps {
  defaultComponent: React.ReactNode;
  text: string;
  isAdaptive: boolean;
}

export interface HintProps extends Partial<HintWrapperProps> {
  type: StoreAppHintType;
  posY?: (isAdaptive: boolean) => CSSProperties["top"];
  posX?: (isAdaptive: boolean) => CSSProperties["left"];
  onClick?: () => void;
  hintRenderer?: (props: HintRendererProps) => React.ReactNode;
}

const Hint: React.FC<HintProps> = ({
  type,
  onClick = () => {},
  posX = () => "calc(50vw - 50%)",
  posY = () => "calc(50vh - 50%)",
  isDimmedBackground = true,
  hintRenderer = undefined,
}) => {
  const adaptive = useAdaptiveLayout();
  const [t] = useAppTranslation();
  const palette = useAppThemePalette();
  const [isPendingRequest] = useAppLoading();

  const textTranslations = useMemo(
    () =>
      t(AppTranslationText.HintsText, { returnObjects: true }) as unknown as Record<
        StoreAppHintType,
        string
      >,
    [t]
  );
  const hintText = useMemo(() => textTranslations[type], [type, textTranslations]);

  const hintBlock = useMemo(
    () => (
      <Block
        width='100%'
        minWidth='50px'
        maxWidth='310px'
        height='fit-content'
        isThin
        isAccent
        className='f-col'
        style={{
          gap: "20px",
          padding: "16px",
          borderRadius: "16px",
          boxShadow: `0px 10px 30px ${palette.grey[200]}`,
        }}>
        <Text color={palette.white.A700}>{hintText}</Text>

        <div className='d-flex' style={{ marginTop: "auto" }}>
          <Spacer left='auto' />

          <Button
            isTool
            type={ButtonType.White}
            onClick={onClick}
            disabled={isPendingRequest}>
            <Text color={palette.primary.main} isHoverPointer>
              {t(AppTranslationText.HintsButtonOK)}
            </Text>
          </Button>
        </div>
      </Block>
    ),
    [
      hintText,
      isPendingRequest,
      onClick,
      palette.grey,
      palette.primary.main,
      palette.white.A700,
      t,
    ]
  );

  return (
    <HintWrapper isDimmedBackground={isDimmedBackground} className='dialog only-dialog'>
      <div
        style={{
          transform: `translate(${posX(adaptive)},${posY(adaptive)})`,
          width: "fit-content",
        }}>
        {hintRenderer
          ? hintRenderer({
              defaultComponent: hintBlock,
              text: hintText,
              isAdaptive: adaptive,
            })
          : hintBlock}
      </div>
    </HintWrapper>
  );
};

export default Hint;
