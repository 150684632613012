import axios, { AxiosError, AxiosResponse } from "axios";

import i18n from "../../i18n";
import { AppTranslationText } from "../../shared/hooks/useAppTranslation";
import store, { StoreRootDispatch } from "../../store";
import { appNotificationSet } from "../../store/app";
import { userLogout } from "../../store/user";

export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_RESPONSE_TIMEOUT = parseInt(process.env.REACT_APP_API_TIMEOUT);

export const API_DELAY_SEND_IDEA_CONTENT = parseInt(
  process.env.REACT_APP_API_DELAY_SEND_IDEA_CONTENT
);
export const API_DELAY_QUESTION_FREQUENCY = parseInt(
  process.env.REACT_APP_API_DELAY_QUESTION_FREQUENCY
);
export const API_DELAY_QUESTION_INACTIVE = parseInt(
  process.env.REACT_APP_API_DELAY_QUESTION_INACTIVE
);

export const API_LIMIT_UPLOAD_FILES = parseInt(
  process.env.REACT_APP_API_LIMIT_UPLOAD_FILES
);
export const API_LIMIT_UPLOAD_MEDIA_TYPES =
  process.env.REACT_APP_API_LIMIT_UPLOAD_MEDIA_TYPES?.split(" ");
export const API_LIMIT_UPLOAD_SIZE = parseInt(
  process.env.REACT_APP_API_LIMIT_UPLOAD_SIZE
);

export const API_LIMIT_FETCH = parseInt(process.env.REACT_APP_API_LIMIT_FETCH);

export const IDEA_FULFILL_PERCENT = parseInt(process.env.REACT_APP_IDEA_FULFILL_PERCENT);

export const enum APIServicePaths {
  AuthLogin = "auth/local",
  AuthRegister = "auth/local/register",
  AuthRegisterConfirm = "auth/local/confirm",
  AuthPasswordForgot = "auth/forgot-password",
  AuthPasswordReset = "auth/reset-password",

  IdeasRequests = "requests",
  IdeasRequestsScope = "scopes",
  IdeasRequestsGroup = "groups",

  UserMe = "users/me",
  User = "users",
  UserRoles = "users-permissions/roles/all",

  UseCase = "use-cases",

  Ideas = "ideas",
  PostfixIdeasAddParagraphs = "add-paragraphs",
  PostfixIdeasDelParagraphs = "delete-paragraphs",
  PostfixIdeasQuestions = "next-question",
  PostfixIdeasAddQuestions = "add-question",
  PostfixIdeasPublish = "publish",
  IdeasOnReview = "idea-templates",
  IdeasRoles = "idea-roles",
  IdeasPaths = "paths",

  HintsShown = "shown-hints",

  Integrations = "integrations",

  Company = "companies",

  Upload = "upload",
}

const localUser = localStorage.getItem("user");
const isLocalUser = !!localUser;
const localUserData = JSON.parse(isLocalUser ? (localUser as string) : "{}");

export const api = axios.create({
  baseURL: API_HOST,
  headers:
    isLocalUser && localUserData.jwt
      ? { Authorization: `Bearer ${localUserData.jwt}` }
      : {},
  timeout: API_RESPONSE_TIMEOUT,
});

api.interceptors.response.use(
  (resp: AxiosResponse) => {
    const _resp = resp as AxiosResponse & { name?: string };
    if (_resp?.name === "Error") {
      store.dispatch(
        appNotificationSet(
          i18n.t(AppTranslationText.CommonNotificationSServerResponse, {
            status: resp.status,
            statusText: resp.statusText,
          }),
          i18n.t(AppTranslationText.CommonNotificationEServer)
        )
      );
    }
    return resp;
  },
  (err: AxiosError) => {
    const errData = err?.response?.data as
      | { statusCode: number; error: string }
      | undefined;
    if (
      401 === (errData?.statusCode as number) &&
      (errData?.error as string) === "Unauthorized"
    ) {
      store.dispatch(
        appNotificationSet(
          i18n.t(AppTranslationText.CommonErrorSession),
          i18n.t(AppTranslationText.CommonNotificationELogin)
        )
      );
      (store.dispatch as StoreRootDispatch)(userLogout());
    }
    throw err;
  }
);
