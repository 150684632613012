import React, { useMemo } from "react";
import {
  Box,
  Alert,
  IconButton,
  Slide,
  Snackbar,
  SlideProps,
  Tooltip,
  AlertProps,
} from "@mui/material";

import useAppNotification from "./shared/hooks/useAppNotifications";

import Icon, { IconType } from "./components/Common/Icon";
import useAppTranslation, { AppTranslationText } from "./shared/hooks/useAppTranslation";
import Text from "./components/Common/Text";
import useAppThemePalette from "./shared/hooks/useAppThemePalette";

const slideTransition = (props: SlideProps) => {
  return <Slide {...props} direction='up' />;
};

const AppSnackbar: React.FC = () => {
  const [t] = useAppTranslation();
  const [notification, , clearNotification, isSet] = useAppNotification();
  const palette = useAppThemePalette();

  const styles = useMemo<AlertProps["sx"]>(
    () => ({
      borderRadius: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: palette.common.black,
      boxShadow: `0px 10px 10px ${palette.grey[300]}`,
    }),
    [palette.common.black, palette.grey]
  );

  return (
    <Snackbar
      open={isSet}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={notification?.closeDelay ?? 5000}
      onClose={clearNotification}
      transitionDuration={350}
      TransitionComponent={slideTransition}
      sx={(theme) => ({
        maxWidth: "75%",
        [theme.breakpoints.down("sm")]: { maxWidth: "100%" },
        " & ::selection": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.white.A700,
        },
      })}>
      <Alert
        severity={notification?.severity}
        action={
          <Tooltip title={t(AppTranslationText.CommonClose)} describeChild>
            <IconButton size='medium' onClick={clearNotification} disableRipple>
              <Icon isHover type={IconType.Cross} color={palette.common.white} />
            </IconButton>
          </Tooltip>
        }
        sx={styles}>
        <Box display='flex' flexDirection='column' gap='8px'>
          <Text isBold variant='body1' color={palette.secondary.main} isTextSelectable>
            {notification?.title}
          </Text>

          {notification?.msg?.split("\n").map((paragraph, idx) => (
            <Text
              isTextSelectable
              key={`p-mpd-${idx}`}
              variant='subtitle2'
              color={palette.secondary.dark}
              isBreakWord>
              {paragraph}
            </Text>
          ))}
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
