import { selectUserRole, selectUserLocale } from "./userSelectors";
import {
  StoreUserRoleType,
  StoreUserRoleTypeMap2State,
  USER_LOGIN,
  USER_LOGOUT,
  USER_CHANGE_ROLE,
  StoreActUserChangeData,
  StoreUserDataState,
  USER_CHANGE_DATA,
  USER_CHANGE_LOCALE,
} from "./userTypes";
import APIService, {
  APIDataUser,
  APIDataUserDesign,
  APIServiceDataAuthLoginResp,
} from "../../services/APIService";
import {
  appHintsUpdate,
  appNotificationClear,
  appThemeChange,
  appThemeReset,
  selectAppHints,
  StoreAppHintsData,
  StoreAppHintsQueue,
} from "../app";
import { StoreThunkAction } from "../storeTypes";
import { AppLanguages } from "../../shared/hooks/useAppTranslation";

export const userDataStoreUpdate =
  (
    user: APIDataUser,
    isLocalStorageUpdate: boolean = false,
    jwt: string = ""
  ): StoreThunkAction =>
  (dispatch, getState) => {
    dispatch({ type: USER_LOGIN, payload: user });
    dispatch(userChangeRole(user.role.type));

    const schema = user.design as NonNullable<APIDataUserDesign>;
    dispatch(
      appThemeChange(
        schema.schemaName,
        schema.schemaAccent,
        schema.schemaType,
        schema.background
      )
    );

    if (isLocalStorageUpdate) {
      localStorage.setItem("user", JSON.stringify({ jwt, user }));
    }

    if (process.env.NODE_ENV !== "production") {
      return;
    }

    const hintsState = selectAppHints(getState());
    const hints = user.shownHints;

    if (hints?.length) {
      const _newShownHints: Partial<StoreAppHintsData> = {};

      for (const h of hints) {
        if (!hintsState.shown[h.name]) {
          _newShownHints[h.name] = true;
        }
      }

      if (Object.keys(_newShownHints).length) {
        let _newQueue: StoreAppHintsQueue = [];
        if (hintsState.queue.length) {
          _newQueue = hintsState.queue.filter((q) => !_newShownHints[q.type]);
        }

        dispatch(
          appHintsUpdate({
            queue:
              _newQueue.length !== hintsState.queue.length ? _newQueue : hintsState.queue,
            shown: { ...hintsState.shown, ..._newShownHints },
          })
        );
      }
    }
  };

export const userLogin =
  (resp?: any): StoreThunkAction =>
  async (dispatch) => {
    dispatch(appNotificationClear());

    if (resp) {
      const r = resp as APIServiceDataAuthLoginResp;
      const jwt = r.data?.jwt as string;
      APIService.tokenUpdate(r?.data?.jwt as string);

      const user = r.data?.user as StoreUserDataState;
      dispatch(userDataStoreUpdate(user as APIDataUser, true, jwt));
    }
  };

export const userLogout = (): StoreThunkAction => (dispatch, getState) => {
  APIService.tokenClear();
  localStorage.removeItem("user");

  dispatch({ type: USER_LOGOUT });
  dispatch(appThemeReset());
};

export const userChangeRole =
  (roleType: StoreUserRoleType): StoreThunkAction =>
  (dispatch, getState) => {
    const roleState = selectUserRole(getState());
    const _newRole = roleType ?? StoreUserRoleType.Creator;

    if (roleState.type !== _newRole) {
      dispatch({ type: USER_CHANGE_ROLE, payload: StoreUserRoleTypeMap2State[_newRole] });
    }
  };

export const userChangeLocale =
  (lang: AppLanguages): StoreThunkAction =>
  (dispatch, getState) => {
    const localeState = selectUserLocale(getState());

    if (lang !== localeState) {
      dispatch({ type: USER_CHANGE_LOCALE, payload: lang });

      const localUser = localStorage.getItem("user");
      if (localUser) {
        const udata = JSON.parse(localUser) as { user: APIDataUser };
        udata.user.locale = lang;
        localStorage.setItem("user", JSON.stringify(udata));
      }
    }
  };

export const userChangeData = (
  userData: NonNullable<StoreUserDataState>
): StoreActUserChangeData => {
  return { type: USER_CHANGE_DATA, payload: userData };
};
