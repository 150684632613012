import {
  StoreUserRoleMainBlockTab,
  StoreUserRoleSettingsTab,
  StoreUserRoleState,
  StoreUserRoleType,
  StoreUserStateDefault,
  StoreUserStateNames,
} from ".";
import { StoreSelectorBase } from "..";
import { AppLanguages } from "../../shared/hooks/useAppTranslation";

import {
  StoreUserState,
  StoreUserName,
  StoreUserDataState,
  StoreUserRoleIdeasQueryType,
} from "./userTypes";

export const selectUserState: StoreSelectorBase<StoreUserState> = (s) =>
  s && s[StoreUserName] ? s[StoreUserName] : StoreUserStateDefault;
export const selectUserData: StoreSelectorBase<StoreUserDataState> = (s) => {
  const userState = selectUserState(s);

  return userState[StoreUserStateNames.Data];
};
export const selectUserLocale: StoreSelectorBase<AppLanguages> = (s) => {
  return selectUserData(s)?.locale ?? AppLanguages.EN;
};
export const selectUserIsLoggedIn: StoreSelectorBase<boolean> = (s) =>
  !!selectUserData(s);

export const selectUserRole: StoreSelectorBase<StoreUserRoleState> = (s) => {
  return selectUserState(s)[StoreUserStateNames.Role];
};
export const selectUserRoleType: StoreSelectorBase<StoreUserRoleType> = (s) => {
  return selectUserState(s)[StoreUserStateNames.Role].type;
};
export const selectUserRoleMainTabs: StoreSelectorBase<StoreUserRoleMainBlockTab[]> = (
  s
) => {
  return selectUserState(s)[StoreUserStateNames.Role].mainTabs;
};
export const selectUserRoleSettingsTabs: StoreSelectorBase<StoreUserRoleSettingsTab[]> = (
  s
) => {
  return selectUserState(s)[StoreUserStateNames.Role].settingsTabs;
};
export const selectUserRoleIdeaQueries: StoreSelectorBase<
  StoreUserRoleIdeasQueryType[]
> = (s) => {
  return selectUserState(s)[StoreUserStateNames.Role].queries;
};
