import React from "react";
import { motion, Transition, Variants, MotionStyle, MotionProps } from "framer-motion";

import { onClickHandler } from "../types";

const _motionDivDefaultStyle: MotionStyle = {};

export const enum MotionDivVariant {
  LeftInitial = "initial",
  LeftIn = "in",
  LeftOut = "out",

  RightInitial = "rinitial",
  RightIn = "rin",
  RightOut = "rout",

  OpacityInitial = "opacityInitial",
  OpacityIn = "opacityIn",
  OpacityOut = "opacityOut",

  HeightInitial = "heightInitial",
  HeightIn = "heightIn",
  HeightOut = "heightOut",
}

const _motionDivDefaultVariants: Variants = {
  [MotionDivVariant.LeftInitial]: (custom) => ({
    opacity: 0,
    x: "-42vw",
    width: custom?.isNoWidthTransition ? "auto" : 0,
  }),
  [MotionDivVariant.LeftIn]: {
    opacity: 1,
    x: 0,
    width: "auto",
  },
  [MotionDivVariant.LeftOut]: (custom) => ({
    opacity: 0,
    x: "42vw",
    width: custom?.isNoWidthTransition ? "auto" : 0,
  }),

  [MotionDivVariant.RightInitial]: (custom) => ({
    opacity: 0,
    x: "42vw",
    width: custom?.isNoWidthTransition ? "auto" : 0,
    scale: 1,
  }),
  [MotionDivVariant.RightIn]: {
    opacity: 1,
    x: 0,
    width: "auto",
  },
  [MotionDivVariant.RightOut]: (custom) => ({
    opacity: 0,
    x: "-42vw",
    width: custom?.isNoWidthTransition ? "auto" : 0,
  }),

  [MotionDivVariant.OpacityInitial]: {
    opacity: 0,
  },
  [MotionDivVariant.OpacityIn]: {
    opacity: 1,
  },
  [MotionDivVariant.OpacityOut]: {
    opacity: 0,
  },

  [MotionDivVariant.HeightInitial]: (custom) => ({
    height: 0,
    minHeight: custom?.minHeight ?? 0,
    opacity: 0,
    scale: 0.98,
  }),
  [MotionDivVariant.HeightIn]: {
    height: "auto",
    opacity: "100%",
    scale: 1,
  },
  [MotionDivVariant.HeightOut]: (custom) => ({
    height: 0,
    minHeight: custom?.minHeight ?? 0,
    opacity: 0,
    scale: 0.92,
  }),
};

export const enum MotionDivTransition {
  VerySlow = "vslow",
  Slow = "slow",
  Default = "default",

  Average = "avg",
  Fast = "fast",
}

const _motionDivTransitionMap: Record<MotionDivTransition, Transition> = {
  [MotionDivTransition.VerySlow]: {
    type: "tween",
    duration: 0.75,
    ease: "easeInOut",
  },
  [MotionDivTransition.Slow]: {
    type: "tween",
    duration: 0.5,
    ease: "easeInOut",
  },
  [MotionDivTransition.Default]: {
    type: "tween",
    duration: 0.35,
    ease: "easeInOut",
  },
  [MotionDivTransition.Average]: {
    type: "tween",
    duration: 0.23,
    ease: "easeInOut",
  },
  [MotionDivTransition.Fast]: {
    type: "tween",
    duration: 0.15,
    ease: "easeInOut",
  },
};

interface MotionDivProps {
  children?: React.ReactNode;
  motionKey?: React.Key | null | undefined;
  className?: string | null | undefined;
  style?: MotionStyle;
  variants?: Variants;
  transition?: MotionDivTransition | Transition;
  initial?: MotionDivVariant;
  animate?: MotionDivVariant;
  exit?: MotionDivVariant;
  onClick?: onClickHandler;
  motionProps?: MotionProps;
}

const MotionDiv: React.FC<MotionDivProps> = ({
  children,
  motionKey = null,
  className = "",
  style = _motionDivDefaultStyle,
  transition = MotionDivTransition.Default,
  initial = MotionDivVariant.OpacityInitial,
  animate = MotionDivVariant.OpacityIn,
  exit = MotionDivVariant.OpacityOut,
  onClick,
  motionProps = {},
}) => {
  return (
    <motion.div
      onClick={(e) => {
        onClick && onClick(e);
        return false;
      }}
      className={className ?? ""}
      key={motionKey}
      initial={initial}
      animate={animate}
      exit={exit}
      style={style}
      variants={_motionDivDefaultVariants}
      transition={
        typeof transition === "string" ? _motionDivTransitionMap[transition] : transition
      }
      {...motionProps}>
      {children}
    </motion.div>
  );
};

export default MotionDiv;
