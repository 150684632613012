import { APP_HINTS_UPDATE } from ".";
import {
  APP_LOADING_START,
  APP_LOADING_STOP,
  APP_NOTIFICATION_SET,
  APP_NOTIFICATION_CLEAR,
  APP_MODAL_OPEN,
  APP_MODAL_CLOSE,
  APP_THEME_RESET,
  APP_THEME_CHANGE,
  APP_HINTS_SHOWN,
  APP_HINTS_QUEUE_UPDATE,
  StoreAppState,
  StoreAppStateNames,
  StoreAppStateDefault,
  StoreAppThemeDefault,
  StoreAppModalDefault,
  StoreAppNotificationDefault,
  StoreAppHintType,
  StoreAppActs,
} from "./appTypes";

const appStoreInitState: StoreAppState = {
  ...StoreAppStateDefault,
};

const appReducer = (state = appStoreInitState, action: StoreAppActs): StoreAppState => {
  const themeState = state[StoreAppStateNames.Theme] ?? StoreAppThemeDefault;

  switch (action.type) {
    case APP_LOADING_START:
      return { ...state, [StoreAppStateNames.Loading]: true };
    case APP_LOADING_STOP:
      return { ...state, [StoreAppStateNames.Loading]: false };

    case APP_NOTIFICATION_SET: {
      const notification = action.payload;
      return { ...state, [StoreAppStateNames.Notification]: notification };
    }
    case APP_NOTIFICATION_CLEAR:
      return { ...state, [StoreAppStateNames.Notification]: StoreAppNotificationDefault };

    case APP_MODAL_OPEN:
      return {
        ...state,
        [StoreAppStateNames.Modal]: action?.payload ?? StoreAppModalDefault,
      };
    case APP_MODAL_CLOSE:
      return {
        ...state,
        [StoreAppStateNames.Modal]: null,
      };

    case APP_THEME_RESET:
      return { ...state, [StoreAppStateNames.Theme]: { ...StoreAppThemeDefault } };

    case APP_THEME_CHANGE:
      const theme = action.payload;
      return {
        ...state,
        [StoreAppStateNames.Theme]: { ...themeState, ...theme },
      };

    case APP_HINTS_SHOWN: {
      return {
        ...state,
        [StoreAppStateNames.Hints]: {
          ...state[StoreAppStateNames.Hints],
          shown: {
            ...state[StoreAppStateNames.Hints].shown,
            [action.payload?.type as StoreAppHintType]: action.payload?.isShown,
          },
        },
      };
    }
    case APP_HINTS_QUEUE_UPDATE:
      return {
        ...state,
        [StoreAppStateNames.Hints]: {
          ...state[StoreAppStateNames.Hints],
          queue: [...(action.payload || [])],
        },
      };
    case APP_HINTS_UPDATE:
      return {
        ...state,
        [StoreAppStateNames.Hints]: {
          ...state[StoreAppStateNames.Hints],
          ...(action.payload || {}),
        },
      };

    default:
      return state;
  }
};

export default appReducer;
