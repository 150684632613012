import { useCallback, useEffect, useState } from "react";

export const enum AdaptiveLayoutResolution {
  Desktop = 0,
  Mobile = 925,
}

interface AdaptiveLayoutProps<T>
  extends Partial<
    Record<
      Exclude<AdaptiveLayoutResolution, AdaptiveLayoutResolution.Desktop> | number,
      T
    >
  > {
  [AdaptiveLayoutResolution.Desktop]: T;
}

const _defaultAdaptiveConfig: AdaptiveLayoutProps<boolean> = {
  [AdaptiveLayoutResolution.Mobile]: true,
  [AdaptiveLayoutResolution.Desktop]: false,
};

function useAdaptiveLayout<T = boolean>(
  adaptiveConfig: AdaptiveLayoutProps<T> = _defaultAdaptiveConfig as unknown as AdaptiveLayoutProps<T>
): T {
  const getAdaptiveConfig = useCallback<() => T>(() => {
    const _adaptiveConfigKeys = Object.keys(adaptiveConfig);
    const _defaultRet = adaptiveConfig[AdaptiveLayoutResolution.Desktop];

    if (!_adaptiveConfigKeys?.length) {
      return _defaultRet;
    }

    const windowWidth = window.innerWidth;
    const _adaptiveResolutions = _adaptiveConfigKeys
      .map((resStr) => parseInt(resStr))
      .sort((a, z) => a - z);

    let _res = AdaptiveLayoutResolution.Desktop;
    for (const res of _adaptiveResolutions) {
      if (res > windowWidth) {
        _res = res;
        break;
      }
    }

    return adaptiveConfig[_res] ?? _defaultRet;
  }, [adaptiveConfig]);

  const [currentConfig, setCurrentConfig] = useState<T>(getAdaptiveConfig());

  useEffect(() => {
    const _updateConfigOnResizeHandler = () => {
      setCurrentConfig(getAdaptiveConfig());
    };

    window.addEventListener("resize", _updateConfigOnResizeHandler);
    return () => window.removeEventListener("resize", _updateConfigOnResizeHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentConfig;
}

export default useAdaptiveLayout;
