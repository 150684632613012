import { AxiosError, AxiosResponse } from "axios";
import { AttachmentComponentExtensionData } from "../../components/Common/RichInput";
import {
  IdeaEditorElement,
  IdeaEditorElementQuestion,
} from "../../components/IdeaEditor/_ideaEditorTypes";
import { AppLanguages } from "../../shared/hooks/useAppTranslation";
import {
  StoreAppThemeSchemaName,
  StoreAppThemeAccentName,
  StoreAppThemeSchemaType,
  StoreAppHintType,
} from "../../store/app";
import { StoreUserRoleType } from "../../store/user";

export type APIMethodData = Record<string, unknown> | any;
export type APIHandlerError = (err: AxiosError) => void | undefined;
export type APIHandlerResp<R = any> = (resp: AxiosResponse<R>) => R | undefined | null;
export interface APIResponseDefault<T = unknown> {
  data: T;
}

export interface APIServiceDataAuthLogin {
  identifier: string;
  password: string;
}
export interface APIServiceDataAuthPasswordForgot {
  email: string;
}
export interface APIServiceDataAuthPasswordRecovery {
  code: string;
  password: string;
  passwordConfirmation: string;
}

type APIDataLocalizedObject<K extends string> = Record<
  `${K}${keyof typeof AppLanguages}`,
  string
>;

export type APIDataID = string;
export type APIDataDate = string;

export interface APIDataBase {
  id: APIDataID;
  _id?: APIDataID;
  __v?: number;
  createdAt?: APIDataDate;
  updatedAt?: APIDataDate;
  published_at?: APIDataDate;
  provider?: "local";
}

export interface APIServiceDataIdeaRequest extends APIDataBase {
  title: string;
  description: string;
  scope: string | null;
  group: string | null;
  deadline: Date | null;
}

export interface APIDataMedia extends APIDataBase {
  ext: string;
  hash: string;
  mime: string;
  name: string;
  formats?: {
    thumbnail: Omit<APIDataMedia, "formats">;
  };
  related: APIDataID[];
  url: string;
  size: number;
  height: number;
  width: number;
}
export interface APIDataMediaMinimized {
  id: APIDataID;
  url: string;
  size: number;
  name: string;
}

export interface APIDataCompany extends APIDataBase {
  name: string;
}
export interface APIDataCompanyRole extends APIDataBase {
  name: string;
}

export interface APIDataUserRole extends APIDataBase {
  name: string;
  description: string;
  locale: AppLanguages;
  type: StoreUserRoleType;
}

export interface APIDataUserDesign extends APIDataBase {
  schemaName: StoreAppThemeSchemaName;
  schemaAccent: StoreAppThemeAccentName;
  schemaType: StoreAppThemeSchemaType;
  background: string | null;
  user: APIDataID;
}

export interface APIDataHint extends APIDataBase {
  name: StoreAppHintType;
}

export interface APIDataUser extends APIDataBase {
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  role: APIDataUserRole;
  company: APIDataCompany;
  blocked: boolean;
  confirmed: boolean;
  locale: AppLanguages;
  avatar?: APIDataMedia;
  shownHints?: APIDataHint[];
  publicCode: APIDataID;
  design: APIDataUserDesign;
}

export interface APIServiceDataAuthLoginResp {
  status: boolean;
  error?: Array<Record<string, unknown>>;
  data?: {
    jwt: APIDataID;
    user: APIDataUser;
  };
}

export interface APIDataIdeasInvolvedUserRole extends APIDataBase {
  name: string;
}

export interface APIDataIdeasRequestScope extends APIDataBase {
  name: string;
}
export interface APIDataIdeasRequest extends APIDataBase {
  company: APIDataCompany;
  creator: APIDataUser;
  deadline: APIDataDate;
  description: string;
  scope: APIDataIdeasRequestScope;
  title: string;
}

export interface APIDataUseCase extends Omit<APIDataBase, "provider"> {
  title: string;
  subtitle: string;
  description: string;
  previewPhoto: APIDataMedia;
  provider: string;
  industryFocus: string;
  function: string;
  customerUsed: string;
}

export const enum APIDataQuestionType {
  WithOneVariant = "withOneVariant",
  OpenQuestion = "openQuestion",
}
export const enum APIDataQuestionCategory {}
export interface APIDataQuestionAnswer extends APIDataBase {
  variant?: APIDataQuestionVariant;
  openAnswer?: string;
}
export interface APIDataQuestionVariant
  extends APIDataBase,
    APIDataLocalizedObject<"variant"> {
  question: APIDataQuestion;
  nextQuestion: APIDataQuestion;
}

export interface APIDataQuestion extends APIDataBase, APIDataLocalizedObject<"question"> {
  isOpen: boolean;
  variants: APIDataQuestionVariant[];
  type: APIDataQuestionType;
  category: APIDataQuestionCategory;
}

export interface APIDataPath extends APIDataBase, APIDataLocalizedObject<"name"> {
  company: string;
}

export interface APIDataIdeas extends APIDataBase {
  company: APIDataCompany;
  content: IdeaEditorElement[];
  creator: APIDataUser;
  evaluationPercent: number;
  title: string;
  inspiredUseCase: APIDataID | null;
  isReadyToPublish: boolean;
  levelOfComplexity: number;
  levelOfElaboration: number;
  questions: IdeaEditorElementQuestion[];
  scale: number;
  request: APIDataIdeasRequest | null;
  ideaTemplate: null | APIDataIdeasOnReview;
  path: string;
}

export const enum APIDataIdeasOnReviewDecision {
  Accept = "accept",
  Decline = "decline",
}
export const enum APIDataIdeasOnReviewStage {
  Returned = "returned",
  New = "new",
  Discussion = "discussion",
  Testing = "testing",
  Final = "final",
}
export const enum APIDataIdeasOnReviewStatus {
  Approved = "approved",
  NotApproved = "notapproved",
}
export interface APIDataIdeasOnReviewInvolvedUser extends APIDataBase {
  ideaTemplate: string;
  user: APIDataUser;
  role: APIDataCompanyRole;
}
export interface APIDataIntegrations extends APIDataBase {
  ideaTemplates: APIDataIdeasOnReview[];
  platformLogo: APIDataMedia;
  platformName: string;
  platformColor: string;
}

export interface APIDataIdeasOnReview
  extends APIDataBase,
    APIDataLocalizedObject<
      | "description"
      | "resources"
      | "risks"
      | "value"
      | "shortDescription"
      | "shortResources"
      | "shortRisks"
      | "shortValue"
    > {
  attachments: AttachmentComponentExtensionData[] | null;
  attachmentsCount: number;

  company: APIDataID;
  creator: APIDataUser;
  decision: APIDataIdeasOnReviewDecision | null;
  decisionComment: string | undefined;

  idea: APIDataIdeas;
  integrations: APIDataIntegrations[];
  involvedUsers: APIDataIdeasOnReviewInvolvedUser[];
  involvedUsersCount: number;

  stage: APIDataIdeasOnReviewStage;
  status: APIDataIdeasOnReviewStatus | null;
  title: string;
  useCases: APIDataUseCase[] | null;

  deadline: string;
  testingComment: string;
}
