import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StoreRootDispatch } from "../../store";
import {
  appNotificationClear,
  appNotificationSet,
  selectAppNotification,
  StoreAppNotification,
  StoreAppNotificationConfig,
  StoreAppNotificationDelayDefault,
  StoreAppNotificationSeverity,
  StoreAppNotificationType,
} from "../../store/app";
import useAppTranslation, { AppTranslationText } from "./useAppTranslation";

export interface AppNotificationSetConfig
  extends Partial<Omit<StoreAppNotificationConfig, "msg" | "title">> {
  msg: string;
}

const _defaultConfig: AppNotificationSetConfig = {
  msg: "",

  type: StoreAppNotificationType.E_Server,
  severity: StoreAppNotificationSeverity.Error,
  onClose: undefined,
  closeDelay: StoreAppNotificationDelayDefault,
};

const useAppNotification = (): [
  notification: StoreAppNotification,
  notificationSet: (config: AppNotificationSetConfig) => void,
  notificationClose: () => void,
  isNotificationSet: boolean
] => {
  const [t] = useAppTranslation();
  const dispatch = useDispatch<StoreRootDispatch>();
  const notification = useSelector(selectAppNotification);

  // saved state is required so the snackbar content appears correct when it's closing
  // forceClose used tempo
  const [saved, setSaved] = useState<StoreAppNotification>(notification);
  const [isForceClose, setIsForceClose] = useState(false);
  useEffect(() => {
    const _watcher = async () => {
      if (notification) {
        if (saved) {
          setIsForceClose(true);

          setTimeout(() => {
            setSaved(notification);
            setIsForceClose(false);
          }, 500);
        } else {
          setSaved(notification);
        }
      } else if (!notification) {
        setTimeout(() => setSaved(undefined), 350);
      }
    };

    _watcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const __appNotificationTitles = useMemo<Record<StoreAppNotificationType, string>>(
    () => ({
      /**
       * Error titles:
       */
      [StoreAppNotificationType.E_Login]: t(AppTranslationText.CommonNotificationELogin),
      [StoreAppNotificationType.E_Server]: t(
        AppTranslationText.CommonNotificationEServer
      ),
      [StoreAppNotificationType.E_Form]: t(AppTranslationText.CommonNotificationEForm),
      [StoreAppNotificationType.E_App]: t(AppTranslationText.CommonNotificationEApp),

      /**
       * Success titles:
       */
      [StoreAppNotificationType.S_ServerResponse]: t(
        AppTranslationText.CommonNotificationSServerResponse
      ),
      [StoreAppNotificationType.S_Info]: "App Info",
    }),
    [t]
  );

  const notificationSet = useCallback(
    async (config: AppNotificationSetConfig) => {
      const _cfg: Required<AppNotificationSetConfig> = {
        ...(_defaultConfig as Required<AppNotificationSetConfig>),
        ...config,
      };

      await dispatch(
        appNotificationSet(
          _cfg.msg,
          __appNotificationTitles[_cfg.type] ??
            t(AppTranslationText.CommonNotificationUnknown),
          _cfg.type,
          _cfg.severity,
          _cfg.onClose,
          _cfg.closeDelay
        )
      );
    },
    [__appNotificationTitles, dispatch, t]
  );

  const notificationClear = useCallback(async () => {
    await dispatch(appNotificationClear());
  }, [dispatch]);

  return [saved, notificationSet, notificationClear, !!notification && !isForceClose];
};

export default useAppNotification;
