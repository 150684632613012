import { AnimatePresence } from "framer-motion";
import React, { useCallback, useMemo } from "react";

import Hint from "./components/Common/Hint";
import MotionDiv from "./components/Common/MotionDiv";

import useAppHints from "./shared/hooks/useAppHints";

const AppHints: React.FC = () => {
  const { currentHint, hintDel } = useAppHints();

  const onHintClick = useCallback(() => {
    if (!currentHint) {
      return;
    }

    if (currentHint.onClick) {
      currentHint.onClick();
    }
    hintDel(currentHint.type);
  }, [currentHint, hintDel]);

  const key = useMemo(
    () => (!!currentHint ? `app-hint-${currentHint.type}` : "app-hint-none"),
    [currentHint]
  );

  return (
    <AnimatePresence exitBeforeEnter>
      <MotionDiv key={key}>
        {!!currentHint ? <Hint {...currentHint} onClick={onHintClick} /> : null}
      </MotionDiv>
    </AnimatePresence>
  );
};

export default AppHints;
