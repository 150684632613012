import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  APIServiceDataAuthLogin,
  APIServiceDataAuthLoginResp,
  APIHandlerError,
  APIHandlerResp,
  APIMethodData,
  APIResponseDefault,
  APIDataMedia,
  APIDataMediaMinimized,
  API_LIMIT_FETCH,
} from ".";
import { api, API_HOST, APIServicePaths } from "./api.config";

function _defaultAPIHandlerResp<T>(resp: APIResponseDefault<T>) {
  return resp?.data;
}

/**
 * Service to accessing data that will add a header with a JWT token automatically
 * when requesting authorized resources from API.
 */
class APIService {
  /**
   * Makes a GET request call.
   *
   * @param {string} url an any path
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static _getAPIRequest(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return api.get(url, config);
  }

  /**
   * Makes a POST request call.
   *
   * @param {string} url an any path
   * @param {APIMethodData} data an any data as object
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static _postAPIRequest(
    url: string,
    data: APIMethodData = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return api.post(url, data, config);
  }

  /**
   * Makes a PUT request call.
   *
   * @param {string} url an any path
   * @param {APIMethodData} data an any data
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static _putAPIRequest(
    url: string,
    data: APIMethodData = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return api.put(url, data, config);
  }

  /**
   * Makes a DEL request call.
   *
   * @param {string} url an any path
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static _deleteAPIRequest(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return api.delete(url, config);
  }

  /**
   * RAW axios requests without auth token
   */
  static getRaw(service: APIServicePaths): Promise<AxiosResponse> {
    return axios.get(`${API_HOST}/${service}`);
  }
  static postRaw(service: APIServicePaths, data: any): Promise<AxiosResponse> {
    return axios.post(`${API_HOST}/${service}`, data);
  }
  static putRaw(service: APIServicePaths, data: any): Promise<AxiosResponse> {
    return axios.put(`${API_HOST}/${service}`, data);
  }

  /**
   * Login method
   *
   * @param {string} login user name \ email \ login
   * @param {string} password password
   *
   * @returns {Promise<AxiosResponse>}
   */
  static login(
    loginData: APIServiceDataAuthLogin
  ): Promise<AxiosResponse<APIServiceDataAuthLoginResp>> {
    return this.postRaw(APIServicePaths.AuthLogin, loginData);
  }

  /**
   * Get any data from API
   */
  static get(
    APIServicePath: APIServicePaths,
    start = 0,
    limit = API_LIMIT_FETCH,
    query = ""
  ): Promise<AxiosResponse> {
    return this._getAPIRequest(
      `${APIServicePath}?_start=${start}&_limit=${limit}${
        query?.length ? `&${query}` : ""
      }`
    );
  }
  /**
   * Get count of an any data from API
   */
  static getCount(
    APIServicePath: APIServicePaths,
    query = ""
  ): Promise<AxiosResponse<number>> {
    return this._getAPIRequest(
      `${APIServicePath}/count${query?.length ? `?${query}` : ""}`
    );
  }
  /**
   * Get any data from API by id
   */
  static getByID(
    APIServicePath: APIServicePaths,
    id: number | string,
    query = ""
  ): Promise<AxiosResponse> {
    return this._getAPIRequest(
      `${APIServicePath}/${id}${query?.length ? `?${query}` : ""}`
    );
  }

  /**
   * Post any data with any data
   */
  static post(
    APIServicePath: APIServicePaths | string,
    data: APIMethodData
  ): Promise<AxiosResponse> {
    return this._postAPIRequest(APIServicePath, data);
  }

  /**
   * Upload a file
   */
  static postUpload(file: File): Promise<AxiosResponse<APIDataMedia[]>> {
    const formData = new FormData();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    formData.append("files", file);

    return this._postAPIRequest(APIServicePaths.Upload, formData, config);
  }

  /**
   * Upload a files
   */
  static postUploadMany(files: File[]): Promise<AxiosResponse<APIDataMedia[]>> {
    const formData = new FormData();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    for (const f of files) {
      formData.append("files", f);
    }

    return this._postAPIRequest(APIServicePaths.Upload, formData, config);
  }

  /**
   * Put any data to any data
   */
  static put(
    APIServicePath: APIServicePaths | string,
    anyData: APIMethodData
  ): Promise<AxiosResponse> {
    return this._putAPIRequest(`${APIServicePath}`, anyData);
  }

  /**
   * Put any data to any data by ID
   */
  static putByID(
    APIServicePath: APIServicePaths,
    id: number | string,
    anyData: APIMethodData
  ): Promise<AxiosResponse> {
    return this._putAPIRequest(`${APIServicePath}/${id}`, anyData);
  }

  /**
   * Delete any data by ID
   */
  static delDataByID(
    APIServicePath: APIServicePaths,
    id: number | string
  ): Promise<AxiosResponse> {
    return this._deleteAPIRequest(`${APIServicePath}/${id}`);
  }

  /**
   * --------------------------
   * MAKE REQUEST AND HANDLE IT
   * --------------------------
   */

  static getCountHandler(
    APIServicePath: APIServicePaths,
    errHandler: APIHandlerError,
    query = "",
    respHandler: APIHandlerResp<number> = _defaultAPIHandlerResp
  ) {
    return this.getCount(APIServicePath, query).then(respHandler, errHandler);
  }
  static getHandler<T = any>(
    APIServicePath: APIServicePaths,
    errHandler: APIHandlerError,
    start = 0,
    limit = API_LIMIT_FETCH,
    query = "",
    respHandler: APIHandlerResp<T> = _defaultAPIHandlerResp
  ) {
    return this.get(APIServicePath, start, limit, query).then(respHandler, errHandler);
  }
  static getByIDHandler<T = any>(
    APIServicePath: APIServicePaths,
    id: number | string,
    errHandler: APIHandlerError,
    query = "",
    respHandler: APIHandlerResp<T> = _defaultAPIHandlerResp
  ) {
    return this.getByID(APIServicePath, id, query).then(respHandler, errHandler);
  }

  static postHandler(
    APIServicePath: APIServicePaths | string,
    data: APIMethodData,
    errHandler: APIHandlerError,
    respHandler: APIHandlerResp = _defaultAPIHandlerResp
  ) {
    return this.post(APIServicePath, data).then(respHandler, errHandler);
  }

  static putHandler(
    APIServicePath: APIServicePaths,
    anyData: APIMethodData,
    errHandler: APIHandlerError,
    respHandler: APIHandlerResp = _defaultAPIHandlerResp
  ) {
    return this.put(APIServicePath, anyData).then(respHandler, errHandler);
  }

  static putByIDHandler(
    APIServicePath: APIServicePaths,
    id: number | string,
    anyData: APIMethodData,
    errHandler: APIHandlerError,
    respHandler: APIHandlerResp = _defaultAPIHandlerResp
  ) {
    return this.putByID(APIServicePath, id, anyData).then(respHandler, errHandler);
  }

  static delDataByIDHandler(
    APIServicePath: APIServicePaths,
    id: number | string,
    errHandler: APIHandlerError,
    respHandler: APIHandlerResp = _defaultAPIHandlerResp
  ) {
    return this.delDataByID(APIServicePath, id).then(respHandler, errHandler);
  }

  /**
   * --------------------------
   * Additional methods to work with JWT token
   * --------------------------
   */

  static tokenUpdate = (jwt: string): void => {
    api.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  };

  static tokenClear = (): void => {
    delete api.defaults.headers.common["Authorization"];
  };

  /**
   * --------------------------
   * Just as it is, combine API Host URL with given URL on server
   * --------------------------
   */
  static getURL = (url: string | undefined | null): string =>
    !!url?.length ? `${API_HOST}${url}` : "";

  static getMinimizedMediaObject = (media: APIDataMedia): APIDataMediaMinimized => ({
    id: media?.id ?? "",
    url: media.url,
    name: media.name,
    size: media.size,
  });
}

export default APIService;
