import { useCallback, useEffect, useState } from "react";

const _defaultAppTitle = "U.Stork Platform";

const useDocumentTitle = (
  defaultTitle?: string
): [string | undefined, (newTitle?: string) => void] => {
  const [title, setTitle] = useState<string | undefined>(defaultTitle);

  const onChangeTitle = useCallback(
    (newTitle?: string): void => {
      if (newTitle !== title) {
        setTitle(newTitle);
      }
    },
    [title, setTitle]
  );

  useEffect(() => {
    if (title) {
      document.title = `${_defaultAppTitle} | ${title}`;
    } else {
      document.title = _defaultAppTitle;
    }

    return () => {
      if (title) {
        setTitle(undefined);
      }

      document.title = _defaultAppTitle;
    };
  }, [title]);

  return [title, onChangeTitle];
};

export default useDocumentTitle;
