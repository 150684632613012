import AppLoader, { AppLoaderProps } from "./AppLoader";
import MotionDiv, {
  MotionDivTransition,
  MotionDivVariant,
} from "./components/Common/MotionDiv";

interface AppSuspenseLoaderProps extends AppLoaderProps {}

const AppSuspenseLoader: React.FC<AppSuspenseLoaderProps> = (props) => {
  return (
    <MotionDiv
      initial={MotionDivVariant.OpacityInitial}
      animate={MotionDivVariant.OpacityIn}
      exit={MotionDivVariant.OpacityOut}
      transition={MotionDivTransition.Average}>
      <AppLoader {...props} />
    </MotionDiv>
  );
};
export default AppSuspenseLoader;
