export const enum StoreAppThemeSchemaName {
  Light = "light",
  Dark = "dark",

  ReadingModeLight = "rm_light",
  ReadingModeDark = "rm_dark",
}

export const enum StoreAppThemeAccentName {
  Banana = "banana",
  Tangerine = "tangerine",
  Strawberry = "strawberry",
  Lime = "lime",
  Tarragon = "tarragon",
  Mint = "mint",
  Curacao = "curacao",
  Blueberry = "blueberry",
  Violet = "violet",
  Raspberry = "raspberry",
  Gray = "gray",

  Iroko = "iroko",
  Pesto = "pesto",
  Woodland = "woodland",
  Everglade = "everglade",
  Amazon = "amazon",
  Spectra = "spectra",
  Firefly = "firefly",
  Lynch = "lynch",
  Eminence = "eminence",
  Grape = "grape",
  Cosmic = "cosmic",
  Lotus = "lotus",
  Alto = "alto",
  Concord = "concord",
}

export const enum StoreAppThemeSchemaType {
  Default = "default",
  ReadingMode = "rm",
}

export const StoreAppThemeSchemaTypeDefaultSchemas = [
  StoreAppThemeSchemaName.Light,
  StoreAppThemeSchemaName.Dark,
];
export const StoreAppThemeSchemaTypeDefaultAccents = [
  StoreAppThemeAccentName.Banana,
  StoreAppThemeAccentName.Tangerine,
  StoreAppThemeAccentName.Strawberry,
  StoreAppThemeAccentName.Lime,
  StoreAppThemeAccentName.Tarragon,
  StoreAppThemeAccentName.Mint,
  StoreAppThemeAccentName.Curacao,
  StoreAppThemeAccentName.Blueberry,
  StoreAppThemeAccentName.Violet,
  StoreAppThemeAccentName.Raspberry,
  StoreAppThemeAccentName.Gray,
];

export const StoreAppThemeSchemaTypeReadingModeAccents = [
  StoreAppThemeAccentName.Iroko,
  StoreAppThemeAccentName.Pesto,
  StoreAppThemeAccentName.Woodland,
  StoreAppThemeAccentName.Everglade,
  StoreAppThemeAccentName.Amazon,
  StoreAppThemeAccentName.Spectra,
  StoreAppThemeAccentName.Firefly,
  StoreAppThemeAccentName.Lynch,
  StoreAppThemeAccentName.Eminence,
  StoreAppThemeAccentName.Grape,
  StoreAppThemeAccentName.Cosmic,
  StoreAppThemeAccentName.Lotus,
  StoreAppThemeAccentName.Alto,
  StoreAppThemeAccentName.Concord,
];
export const StoreAppThemeSchemaTypeReadingModeSchemas = [
  StoreAppThemeSchemaName.ReadingModeLight,
  StoreAppThemeSchemaName.ReadingModeDark,
];

export const StoreAppThemeSchemaType2Accents: Record<
  StoreAppThemeSchemaType,
  StoreAppThemeAccentName[]
> = {
  [StoreAppThemeSchemaType.Default]: StoreAppThemeSchemaTypeDefaultAccents,
  [StoreAppThemeSchemaType.ReadingMode]: StoreAppThemeSchemaTypeReadingModeAccents,
};
export const StoreAppThemeSchemaType2Schemas: Record<
  StoreAppThemeSchemaType,
  StoreAppThemeSchemaName[]
> = {
  [StoreAppThemeSchemaType.Default]: StoreAppThemeSchemaTypeDefaultSchemas,
  [StoreAppThemeSchemaType.ReadingMode]: StoreAppThemeSchemaTypeReadingModeSchemas,
};

export const StoreAppThemeSchemaTypeMap2Schemas: Record<
  StoreAppThemeSchemaType,
  { light: StoreAppThemeSchemaName; dark: StoreAppThemeSchemaName }
> = {
  [StoreAppThemeSchemaType.Default]: {
    light: StoreAppThemeSchemaName.Light,
    dark: StoreAppThemeSchemaName.Dark,
  },
  [StoreAppThemeSchemaType.ReadingMode]: {
    light: StoreAppThemeSchemaName.ReadingModeLight,
    dark: StoreAppThemeSchemaName.ReadingModeDark,
  },
};

export interface StoreAppThemeSchema {
  name: StoreAppThemeSchemaName;
  type: StoreAppThemeSchemaType;
  accent: StoreAppThemeAccentName;
}
/* URL to an image or null to use an accent color as bg */
export type StoreAppThemeBackground = string | null | undefined;

export interface StoreAppThemeConfig {
  schema: StoreAppThemeSchema;
  background?: StoreAppThemeBackground;
  lastChange?: Date | null;
}

export type StoreAppTheme = StoreAppThemeConfig | undefined | null;

export const enum StoreAppThemeAccentHEX {
  Banana = "#FFCC00",
  Tangerine = "#FF9500",
  Strawberry = "#E53900",
  Lime = "#D7EB00",
  Tarragon = "#5ED921",
  Mint = "#00D9A3",
  Curacao = "#00CFE2",
  Blueberry = "#007ED9",
  Violet = "#8B24F2",
  Raspberry = "#D90090",
  Gray = "#D4D7D9",

  Iroko = "#7d5c3c",
  Pesto = "#7d7d3c",
  Woodland = "#5c7d3c",
  Everglade = "#3c7d3c",
  Amazon = "#3c7d5c",
  Spectra = "#3c7d7d",
  Firefly = "#3c5c7d",
  Lynch = "#3c3c7d",
  Eminence = "#5c3c7d",
  Grape = "#7d3c7d",
  Cosmic = "#7d3c5c",
  Lotus = "#7d3c3c",
  Alto = "#424242",
  Concord = "#7d7d7d",
}

export const enum StoreAppThemeAccentLightHEX {
  Iroko = "#b58657",
  Pesto = "#b5b557",
  Woodland = "#86b557",
  Everglade = "#57b557",
  Amazon = "#57b586",
  Spectra = "#57b5b5",
  Firefly = "#5786b5",
  Lynch = "#5757b5",
  Eminence = "#8657b5",
  Grape = "#b557b5",
  Cosmic = "#b55786",
  Lotus = "#b55757",
  Alto = "#636363",
  Concord = "#b5b5b5",
}

export const enum StoreAppThemeAccentDarkHEX {
  Banana = "#E5BA39",
  Tangerine = "#CC6D29",
  Strawberry = "#B2411B",
  Lime = "#A1B247",
  Tarragon = "#70BF60",
  Mint = "#3AA68B",
  Curacao = "#24A7B2",
  Blueberry = "#3987BF",
  Violet = "#7C53A6",
  Raspberry = "#A64B87",
  Gray = "#A2A5A6",

  Iroko = "#453321",
  Pesto = "#454521",
  Woodland = "#334521",
  Everglade = "#214521",
  Amazon = "#214533",
  Spectra = "#214545",
  Firefly = "#213345",
  Lynch = "#212145",
  Eminence = "#332145",
  Grape = "#392145",
  Cosmic = "#452133",
  Lotus = "#452121",
  Alto = "#212121",
  Concord = "#454545",
}
