import { USER_CHANGE_DATA, USER_CHANGE_LOCALE } from ".";
import { AppLanguages } from "../../shared/hooks/useAppTranslation";
import {
  StoreUserActs,
  StoreUserDataState,
  StoreUserRoleState,
  StoreUserState,
  StoreUserStateNames,
  StoreUserStateDefault,
  USER_LOGIN,
  USER_LOGOUT,
  USER_CHANGE_ROLE,
} from "./userTypes";

const userStoreInitState: StoreUserState = {
  ...StoreUserStateDefault,
};

const userReducer = (
  state = userStoreInitState,
  action: StoreUserActs
): StoreUserState => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        [StoreUserStateNames.Data]: action.payload as StoreUserDataState,
      };

    case USER_LOGOUT:
      return { ...state, [StoreUserStateNames.Data]: null };

    case USER_CHANGE_ROLE:
      return {
        ...state,
        [StoreUserStateNames.Role]: action.payload as NonNullable<StoreUserRoleState>,
      };
    case USER_CHANGE_DATA:
      return {
        ...state,
        data: { ...action.payload } as StoreUserDataState,
      };
    case USER_CHANGE_LOCALE:
      return {
        ...state,
        data: {
          ...state.data,
          locale: action?.payload ?? AppLanguages.EN,
        } as StoreUserDataState,
      };

    default:
      return state;
  }
};

export default userReducer;
