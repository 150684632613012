import isBrowser from "./isBrowser";

/**
 * Just a little function that will scroll page to pos
 *
 * @param {number} pos a position to which one is need to scroll
 */
export let scrollToTopPosition = (pos = 0): void => {
  scrollToPosition(pos);
};

let scrollToPosition = (y: number = 0, x: number = 0): void => {
  if (isBrowser()) {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    scrollToPosition = (y, x): void => {
      window.scrollTo({
        top: y,
        left: x,
        behavior: "smooth",
      });
    };
  } else {
    scrollToTopPosition = (): void => {};
  }

  scrollToPosition(y, x);
};

export default scrollToPosition;
