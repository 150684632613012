import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

import Icon, { IconType } from "./components/Common/Icon";

import { selectAppThemeBackground, StoreAppThemeAccentHEX } from "./store/app";
import { getCSSTransition } from "./shared/functions/colorsMath";
import useAppThemePalette from "./shared/hooks/useAppThemePalette";
import useAdaptiveLayout from "./shared/hooks/useAdaptiveLayout";

interface AppLoaderStyledProps {
  bg?: string | undefined | null;
  accentClr?: string;
  isLoading?: boolean;
}

const AppLoaderStyled = styled.div<AppLoaderStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background: ${({ bg, accentClr, isLoading }) =>
    `${
      bg ? `url("${bg}")` : isLoading ? "url(/assets/bgs/Cover.jpg)" : "none"
    } ${accentClr} center no-repeat`};
  transition: ${getCSSTransition()};

  background-size: cover;
  background-attachment: fixed;
`;

export interface AppLoaderProps extends AppLoaderStyledProps {
  isUserBg?: boolean;
}

const AppLoader: React.FC<AppLoaderProps> = ({ isUserBg = true, ...props }) => {
  const background = useSelector(selectAppThemeBackground);
  const palette = useAppThemePalette();
  const adaptive = useAdaptiveLayout();

  return (
    <AppLoaderStyled
      className='d-flex f-col'
      accentClr={isUserBg ? palette.primary.main : StoreAppThemeAccentHEX.Banana}
      bg={isUserBg ? background : undefined}
      {...props}>
      <Icon
        type={IconType.UStorkLogo}
        width={adaptive ? "70%" : "42%"}
        height='100%'
        opacity='100%'
        color={palette.grey.A700}
        className='app-loader-anim'
      />
    </AppLoaderStyled>
  );
};

export default AppLoader;
