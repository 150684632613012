import { StoreThunkAction } from "../storeTypes";
import {
  APP_LOADING_START,
  APP_LOADING_STOP,
  APP_NOTIFICATION_SET,
  APP_NOTIFICATION_CLEAR,
  APP_MODAL_OPEN,
  APP_MODAL_CLOSE,
  APP_THEME_RESET,
  APP_THEME_CHANGE,
  StoreActAppLoadingStart,
  StoreActAppLoadingStop,
  StoreAppNotificationDelayDefault,
  StoreActAppNotificationSet,
  StoreActAppThemeReset,
  StoreAppNotificationSeverity,
  StoreAppNotificationType,
  StoreAppModalConfig,
  StoreActAppModalOpen,
  StoreActAppModalClose,
  StoreAppHintType,
  APP_HINTS_SHOWN,
  APP_HINTS_QUEUE_UPDATE,
  StoreAppHintsConfig,
  StoreActAppHintsUpdate,
  APP_HINTS_UPDATE,
  StoreActAppHintsQueueUpdate,
} from "./appTypes";
import {
  selectAppHintShown,
  selectAppNotification,
  selectAppTheme,
} from "./appSelectors";
import {
  StoreAppThemeConfig,
  StoreAppThemeSchemaName,
  StoreAppThemeSchemaType,
  StoreAppThemeAccentName,
  StoreAppThemeSchemaType2Accents,
  StoreAppThemeSchemaType2Schemas,
} from "./_appThemeTypes";
import { HintProps } from "../../components/Common/Hint";

export const appLoadingStart = (): StoreActAppLoadingStart => ({
  type: APP_LOADING_START,
});
export const appLoadingStop = (): StoreActAppLoadingStop => ({ type: APP_LOADING_STOP });

/**
 * It sets the notification state.
 * @param {string} msg - The message to display in the notification.
 * @param {string} title - The title of the notification.
 * @param {StoreAppNotificationType} type - The type of notification.
 * @param {StoreAppNotificationSeverity} severity - The severity of notification.
 * @param {(() => void) | undefined} onClose - The on close notification callback.
 * @param {number} closeDelay - The notification close delay
 * @returns A `StoreActAppNotificationSet` action.
 */
export const appNotificationSet = (
  msg: string,
  title: string,
  type: StoreAppNotificationType = StoreAppNotificationType.E_Server,
  severity: StoreAppNotificationSeverity = StoreAppNotificationSeverity.Error,
  onClose: (() => void) | undefined = undefined,
  closeDelay: number = StoreAppNotificationDelayDefault
): StoreActAppNotificationSet => {
  return {
    type: APP_NOTIFICATION_SET,
    payload: {
      title,
      msg,
      type,
      severity,
      onClose,
      closeDelay,
    },
  };
};

/**
 * Clear the app notification.
 */
export const appNotificationClear = (): StoreThunkAction => (dispatch, getState) => {
  const appNotificationState = selectAppNotification(getState());

  if (appNotificationState) {
    dispatch({ type: APP_NOTIFICATION_CLEAR });
  }
};

/**
 * It creates an action that will be dispatched to the store.
 * @param modalData - The data that will be passed to the modal.
 */
export const appModalOpen = (modalData: StoreAppModalConfig): StoreActAppModalOpen => ({
  type: APP_MODAL_OPEN,
  payload: modalData,
});

/**
 * It creates an action that will close the modal.
 */
export const appModalClose = (): StoreActAppModalClose => ({
  type: APP_MODAL_CLOSE,
});
/**
 * It returns an action that will reset the app theme.
 */
export const appThemeReset = (): StoreActAppThemeReset => ({ type: APP_THEME_RESET });

/**
 * "Change the app theme to a new theme."
 *
 * The function is a thunk action, which means it's a function that returns a function
 * @param {StoreAppThemeSchemaName} schema - The name of the schema to use.
 * @param {StoreAppThemeAccentName} accent - The accent color to use.
 * @param {StoreAppThemeSchemaType} type - The schema type to use.
 * @param {string | null} bg - The app background to use.
 * @returns Nothing.
 */
export const appThemeChange =
  (
    schema: StoreAppThemeSchemaName,
    accent: StoreAppThemeAccentName,
    type: StoreAppThemeSchemaType,
    bg: string | null = null,
    lastChangeDate: Date | null = null
  ): StoreThunkAction =>
  (dispatch, getState) => {
    const _s = getState();
    const themeState = selectAppTheme(_s);
    const schemaState = themeState.schema;

    let newSchema = schemaState.name !== schema ? schema : schemaState.name;
    let newSchemaType = schemaState.type;
    let newAccent = schemaState.accent !== accent ? accent : schemaState.accent;

    if (schemaState.type !== type) {
      newSchemaType = type;

      const _availableAccents = StoreAppThemeSchemaType2Accents[type];
      if (_availableAccents.includes(accent)) {
        newAccent = accent;
      } else {
        newAccent = _availableAccents[0];
      }

      const _availableSchemas = StoreAppThemeSchemaType2Schemas[type];
      if (_availableSchemas.includes(schema)) {
        newSchema = schema;
      } else {
        newSchema = _availableSchemas[0];
      }
    }

    const newTheme: Required<StoreAppThemeConfig> = {
      schema: {
        name: newSchema,
        accent: newAccent,
        type: newSchemaType,
      },
      background: themeState.background !== bg ? bg : themeState.background,
      lastChange: lastChangeDate ?? new Date(),
    };

    dispatch({ type: APP_THEME_CHANGE, payload: newTheme });
  };

export const appHintsShown =
  (type: StoreAppHintType): StoreThunkAction =>
  (dispatch, getState) => {
    const isMarkedAsShown = selectAppHintShown(type)(getState());

    if (!isMarkedAsShown) {
      dispatch({
        type: APP_HINTS_SHOWN,
        payload: { type, isShown: true },
      });
    }
  };

export const appHintsQueueUpdate = (
  newQueue: HintProps[]
): StoreActAppHintsQueueUpdate => ({
  type: APP_HINTS_QUEUE_UPDATE,
  payload: newQueue,
});

export const appHintsUpdate = (
  newState: StoreAppHintsConfig
): StoreActAppHintsUpdate => ({
  type: APP_HINTS_UPDATE,
  payload: newState,
});
