import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHotkeys, Options } from "react-hotkeys-hook";

import { AppRoutingPaths } from "./AppRoutes";
import APIService, { APIDataUser, APIServicePaths } from "./services/APIService";
import { scrollToTopPosition } from "./shared/functions/scrollToPosition";
import useAppModal from "./shared/hooks/useAppModal";
import useAppNotification from "./shared/hooks/useAppNotifications";
import useAppTranslation, { AppTranslationText } from "./shared/hooks/useAppTranslation";
import {
  appHintsQueueUpdate,
  appThemeChange,
  selectAppThemeSchema,
  StoreAppNotificationSeverity,
  StoreAppNotificationType,
  StoreAppThemeSchemaName,
  StoreAppThemeSchemaType,
  StoreAppThemeSchemaType2Accents,
} from "./store/app";
import { userDataStoreUpdate, userLogout } from "./store/user";
import useAppHints from "./shared/hooks/useAppHints";

interface AppFeaturesProps {
  setIsFakeLoader: (val: boolean) => void;
  isUserLoggedIn: boolean;
}

const AppFeatures: React.FC<AppFeaturesProps> = ({ setIsFakeLoader, isUserLoggedIn }) => {
  const schema = useSelector(selectAppThemeSchema);
  const dispatch = useDispatch();
  const location = useLocation();
  const [notification, setNotification, clearNotification] = useAppNotification();
  const { hintsState } = useAppHints();
  const [isOpenModal, , , onCloseModal] = useAppModal();
  const [t] = useAppTranslation();

  const [isThemingShortcutsAccessible, setIsThemingShortcutsAccessible] = useState(
    location.pathname === AppRoutingPaths.Ideas
  );

  /**
   * On page change checks logics
   *
   * clear notifications
   * clear hints
   * close modal
   * theming shortcuts works only on the main-home page
   */
  useEffect(() => {
    if (notification) {
      clearNotification();
    }
    if (hintsState.queue.length) {
      dispatch(appHintsQueueUpdate([]));
    }
    if (isOpenModal) {
      onCloseModal();
    }

    const _isTheming = location.pathname === AppRoutingPaths.Ideas;
    if (_isTheming !== isThemingShortcutsAccessible) {
      setIsThemingShortcutsAccessible(_isTheming);
    }
    scrollToTopPosition(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   * Auto-fetch user data on app open.
   */
  useEffect(() => {
    const _fetchUserData = async () => {
      const ud = await APIService.getHandler<APIDataUser>(APIServicePaths.UserMe, () => {
        setNotification({ msg: t(AppTranslationText.CommonErrorUserMe) });
        dispatch(userLogout());
      });

      if (ud) {
        dispatch(userDataStoreUpdate(ud));
      }

      setTimeout(() => {
        setIsFakeLoader(false);
      }, 1200);
    };

    if (isUserLoggedIn) {
      _fetchUserData();
    } else {
      setTimeout(() => {
        setIsFakeLoader(false);
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * App Theming Shortcuts
   */

  const _hotKeysOptions = useMemo<Options>(
    () => ({ enabled: isThemingShortcutsAccessible }),
    [isThemingShortcutsAccessible]
  );

  const _showNewSchemaInfo = useCallback(
    (title: string, subtitle: any) => {
      setNotification({
        msg: `${title}\n${subtitle}`,
        severity: StoreAppNotificationSeverity.Info,
        type: StoreAppNotificationType.S_Info,
      });
    },
    [setNotification]
  );

  interface _schemaChangeData {
    schema: StoreAppThemeSchemaName;
    type: StoreAppThemeSchemaType;
  }

  const _themeSchemaReaderMode = useCallback(() => {
    const _to: Record<StoreAppThemeSchemaName, _schemaChangeData> = {
      [StoreAppThemeSchemaName.Light]: {
        schema: StoreAppThemeSchemaName.ReadingModeLight,
        type: StoreAppThemeSchemaType.ReadingMode,
      },
      [StoreAppThemeSchemaName.Dark]: {
        schema: StoreAppThemeSchemaName.ReadingModeDark,
        type: StoreAppThemeSchemaType.ReadingMode,
      },
      [StoreAppThemeSchemaName.ReadingModeLight]: {
        schema: StoreAppThemeSchemaName.Light,
        type: StoreAppThemeSchemaType.Default,
      },
      [StoreAppThemeSchemaName.ReadingModeDark]: {
        schema: StoreAppThemeSchemaName.Dark,
        type: StoreAppThemeSchemaType.Default,
      },
    };

    const _newSchema = _to[schema.name];

    dispatch(appThemeChange(_newSchema.schema, schema.accent, _newSchema.type));
    _showNewSchemaInfo("Type Changer", `${schema.type} -> ${_newSchema.type}`);
  }, [_showNewSchemaInfo, dispatch, schema]);
  useHotkeys("shift+r", _themeSchemaReaderMode, _hotKeysOptions, [schema]);

  const _themeSchemaChanger = useCallback(() => {
    const _to: Record<StoreAppThemeSchemaName, _schemaChangeData> = {
      [StoreAppThemeSchemaName.Light]: {
        schema: StoreAppThemeSchemaName.Dark,
        type: StoreAppThemeSchemaType.Default,
      },
      [StoreAppThemeSchemaName.Dark]: {
        schema: StoreAppThemeSchemaName.Light,
        type: StoreAppThemeSchemaType.Default,
      },
      [StoreAppThemeSchemaName.ReadingModeLight]: {
        schema: StoreAppThemeSchemaName.ReadingModeDark,
        type: StoreAppThemeSchemaType.ReadingMode,
      },
      [StoreAppThemeSchemaName.ReadingModeDark]: {
        schema: StoreAppThemeSchemaName.ReadingModeLight,
        type: StoreAppThemeSchemaType.ReadingMode,
      },
    };

    const _newSchema = _to[schema.name];

    dispatch(appThemeChange(_newSchema.schema, schema.accent, _newSchema.type));
    _showNewSchemaInfo("Schema Changer", `${schema.name} -> ${_newSchema.schema}`);
  }, [_showNewSchemaInfo, dispatch, schema]);
  useHotkeys("shift+s", _themeSchemaChanger, _hotKeysOptions, [schema]);

  const _themeAccentChangerNext = useCallback(() => {
    const accents = StoreAppThemeSchemaType2Accents[schema.type];
    const accentIdx = accents.findIndex((v) => v === schema.accent);
    const newIdx = accentIdx + 1 === accents.length ? 0 : accentIdx + 1;
    const newAccent = accents[newIdx];

    dispatch(appThemeChange(schema.name, newAccent, schema.type));
    _showNewSchemaInfo("Accent Changer", `${schema.accent} -> ${newAccent}`);
  }, [_showNewSchemaInfo, dispatch, schema]);
  useHotkeys("shift+a", _themeAccentChangerNext, _hotKeysOptions, [schema]);

  const _themeAccentChangerPrev = useCallback(() => {
    const accents = StoreAppThemeSchemaType2Accents[schema.type];
    const accentIdx = accents.findIndex((v) => v === schema.accent);
    const newIdx = !accentIdx ? accents?.length - 1 : accentIdx - 1;
    const newAccent = accents[newIdx];

    dispatch(appThemeChange(schema.name, newAccent, schema.type));
    _showNewSchemaInfo("Accent Changer", `${schema.accent} -> ${newAccent}`);
  }, [_showNewSchemaInfo, dispatch, schema]);
  useHotkeys("shift+z", _themeAccentChangerPrev, _hotKeysOptions, [schema]);

  return null;
};

export default AppFeatures;
