import { StoreSelectorBase } from "../storeTypes";

import {
  StoreAppStateNames,
  StoreAppStateDefault,
  StoreAppThemeDefault,
  StoreAppState,
  StoreAppName,
  StoreAppNotification,
  StoreAppModal,
  StoreAppThemeSchemaStateDefault,
  StoreAppHintsConfig,
  StoreAppHintType,
  StoreAppHintsQueue,
} from "./appTypes";
import {
  StoreAppThemeSchemaName,
  StoreAppThemeSchemaType,
  StoreAppThemeConfig,
  StoreAppThemeAccentName,
  StoreAppThemeBackground,
  StoreAppThemeSchema,
} from "./_appThemeTypes";

export const selectAppState: StoreSelectorBase<StoreAppState> = (s) =>
  s[StoreAppName] ?? StoreAppStateDefault;

export const selectAppLoading: StoreSelectorBase<boolean> = (s) =>
  selectAppState(s)[StoreAppStateNames.Loading];

export const selectAppNotification: StoreSelectorBase<StoreAppNotification> = (s) =>
  selectAppState(s)[StoreAppStateNames.Notification];

export const selectAppModal: StoreSelectorBase<StoreAppModal> = (s) =>
  selectAppState(s)[StoreAppStateNames.Modal];

export const selectAppTheme: StoreSelectorBase<NonNullable<StoreAppThemeConfig>> = (s) =>
  selectAppState(s)[StoreAppStateNames.Theme] ?? StoreAppThemeDefault;
export const selectAppThemeSchema: StoreSelectorBase<StoreAppThemeSchema> = (s) =>
  selectAppTheme(s)?.schema ?? { ...StoreAppThemeSchemaStateDefault };
export const selectAppThemeSchemaName: StoreSelectorBase<StoreAppThemeSchemaName> = (s) =>
  selectAppThemeSchema(s).name;
export const selectAppThemeSchemaType: StoreSelectorBase<StoreAppThemeSchemaType> = (s) =>
  selectAppThemeSchema(s).type;
export const selectAppThemeAccent: StoreSelectorBase<StoreAppThemeAccentName> = (s) =>
  selectAppThemeSchema(s).accent;
export const selectAppThemeBackground: StoreSelectorBase<StoreAppThemeBackground> = (s) =>
  selectAppTheme(s)?.background;

export const selectAppHints: StoreSelectorBase<StoreAppHintsConfig> = (s) =>
  selectAppState(s).hints;
export const selectAppHintsQueue: StoreSelectorBase<StoreAppHintsQueue> = (s) =>
  selectAppHints(s).queue;
export const selectAppHintShown: (type: StoreAppHintType) => StoreSelectorBase<boolean> =
  (type) => (s) =>
    selectAppState(s).hints.shown[type];
