import React, { useMemo } from "react";
import { Backdrop, Box, BoxProps, Fade, Modal, useTheme } from "@mui/material";

import useAppModal from "./shared/hooks/useAppModal";
import { getCSSTransition } from "./shared/functions/colorsMath";

const AppModal: React.FC = () => {
  const [isOpen, modal] = useAppModal();
  const theme = useTheme();

  const _staticModalStyle = useMemo(
    () =>
      modal?.isStatic
        ? {}
        : {
            [theme.breakpoints.down("md")]: {
              top: "0",
              left: "0",
              height: "100%",
              maxHeight: "100%",
              width: "100%",
              maxWidth: "100%",
              borderRadius: "0",
              transform: "none",
            },
          },
    [modal?.isStatic, theme.breakpoints]
  );
  const configurableStyle = useMemo<BoxProps["sx"]>(() => modal?.sx ?? {}, [modal?.sx]);
  const modalWrapperStyle = useMemo<BoxProps["sx"]>(
    () => ({
      "&:focus": {
        outline: "none",
      },
    }),
    []
  );
  const modalContentStyle = useMemo<BoxProps["sx"]>(
    () => ({
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      outline: "none",

      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "70%",
      maxWidth: "880px",
      height: "auto",
      maxHeight: "70%",
      borderRadius: "18px",
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      boxShadow: `0 0 60px ${theme.palette.grey[100]}`,
      transition: getCSSTransition(0.35, "background-color"),

      overflow: "auto",
      overflowX: "hidden",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },

      "& ::selection": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.white.A700,
      },

      ..._staticModalStyle,
      ...configurableStyle,
    }),
    [_staticModalStyle, configurableStyle, theme.palette]
  );

  const ContentComponent = useMemo(() => modal?.content, [modal?.content]);

  return (
    <Modal
      keepMounted
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        transitionDuration: 350,
        timeout: 350,
        easing: "ease-in-out",
      }}>
      <Fade in={isOpen}>
        <Box sx={modalWrapperStyle}>
          <Box className={modal?.className} sx={modalContentStyle}>
            {ContentComponent && <ContentComponent />}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AppModal;
