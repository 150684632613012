import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { Theme, ThemeProvider } from "@mui/material";
import styled from "@emotion/styled";

import AppSuspenseLoader from "./AppSuspenseLoader";
import AppSnackbar from "./AppSnackbar";
import AppModal from "./AppModal";
import AppFeatures from "./AppFeatures";
import AppHints from "./AppHints";

import AppRoutes from "./AppRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";

import MotionDiv from "./components/Common/MotionDiv";

import { selectAppThemeBackground, selectAppThemeSchema } from "./store/app";
import { selectUserIsLoggedIn } from "./store/user";
import { getCSSTransition } from "./shared/functions/colorsMath";
import { getAppThemeOptions } from "./theme";

interface AppStyledProps {
  bg: string | undefined | null;
}

const AppStyled = styled.div<AppStyledProps>(
  ({ theme, bg }) => `
  width: 100%;
  height: auto;
  min-height: 100vh;

  color: ${(theme as Theme).palette.common.black};
  background: ${`${bg ? `url(${bg})` : "none"} ${
    (theme as Theme).palette.primary.main
  } center no-repeat`};
  transition: ${getCSSTransition()};

  background-size: cover;
  background-attachment: fixed;

  & ::selection {
    background-color: ${(theme as Theme).palette.primary.main};
    color: ${(theme as Theme).palette.common.black};
  }
`
);

const App = () => {
  const bg = useSelector(selectAppThemeBackground);
  const schema = useSelector(selectAppThemeSchema);
  const isUserLoggedIn = useSelector(selectUserIsLoggedIn);

  const [isFakeLoader, setIsFakeLoader] = useState(true);

  const theme = useMemo(() => getAppThemeOptions(schema.name, schema.accent), [schema]);

  return (
    <ThemeProvider theme={theme}>
      <AppModal />
      <AppSnackbar />
      <AppHints />
      <AppFeatures isUserLoggedIn={isUserLoggedIn} setIsFakeLoader={setIsFakeLoader} />

      <AppStyled bg={isUserLoggedIn ? bg : "/assets/bgs/Cover.jpg"}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <MotionDiv key={isFakeLoader ? "loader" : isUserLoggedIn ? "app" : "auth"}>
            {isFakeLoader ? (
              <AppSuspenseLoader isLoading isUserBg={false} />
            ) : isUserLoggedIn ? (
              <AppRoutes />
            ) : (
              <AuthenticationRoutes />
            )}
          </MotionDiv>
        </AnimatePresence>
      </AppStyled>
    </ThemeProvider>
  );
};

export default App;
