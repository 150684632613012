import React from "react";
import { BoxProps } from "@mui/system";

import { StoreActBase } from "../storeTypes";
import {
  StoreAppTheme,
  StoreAppThemeConfig,
  StoreAppThemeSchema,
  StoreAppThemeAccentName,
  StoreAppThemeSchemaName,
  StoreAppThemeSchemaType,
} from "./_appThemeTypes";
import { HintProps } from "../../components/Common/Hint";

export const StoreAppName = "app";

export const enum StoreAppStateNames {
  Loading = "loading",
  Notification = "notification",
  Modal = "modal",
  Theme = "theme",
  Hints = "hints",
}

/**
 * Notification
 */

export const enum StoreAppNotificationType {
  E_Login = 0,
  E_Server,
  E_Form,
  E_App,

  S_ServerResponse,
  S_Info,
}
export const enum StoreAppNotificationSeverity {
  Error = "error",
  Info = "info",
  Warning = "warning",
  Success = "success",
}

export const StoreAppNotificationDelayDefault = 5000;

export interface StoreAppNotificationConfig {
  title: string;
  msg: string;
  type: StoreAppNotificationType;
  severity: StoreAppNotificationSeverity;
  onClose?: () => void;
  closeDelay: number;
}

export type StoreAppNotification = StoreAppNotificationConfig | undefined | null;
export const StoreAppNotificationDefault = null;

/**
 * Modal
 */

export interface StoreAppModalConfig {
  content: React.FC;
  sx?: BoxProps["sx"];
  isStatic?: boolean;
  className?: string;
}

export type StoreAppModal = StoreAppModalConfig | null | undefined;
export const StoreAppModalDefault: StoreAppModal = null;

/**
 * Theme
 */

export const StoreAppThemeSchemaStateDefault: StoreAppThemeSchema = {
  name: StoreAppThemeSchemaName.Light,
  accent: StoreAppThemeAccentName.Banana,
  type: StoreAppThemeSchemaType.Default,
};

export const StoreAppThemeDefault: StoreAppThemeConfig = {
  schema: { ...StoreAppThemeSchemaStateDefault },
  background: null,
  lastChange: new Date(),
};

/**
 * Hints
 */

export const enum StoreAppHintType {
  EditorGrade = "EGrade",
  EditorAttachments = "EAttachments",
  EditorPublish = "EPublish",
  EditorQuestion = "EQuestion",
}

export interface StoreAppHintsData extends Record<StoreAppHintType, boolean> {}

export type StoreAppHintsQueue = HintProps[];

export interface StoreAppHintsConfig {
  queue: StoreAppHintsQueue;
  shown: StoreAppHintsData;
}

export const StoreAppHintsDataDefault: StoreAppHintsData = {
  [StoreAppHintType.EditorGrade]: false,
  [StoreAppHintType.EditorAttachments]: false,
  [StoreAppHintType.EditorPublish]: false,
  [StoreAppHintType.EditorQuestion]: false,
};

/**
 * Store States
 */

export interface StoreAppState {
  [StoreAppStateNames.Loading]: boolean;
  [StoreAppStateNames.Notification]: StoreAppNotification;
  [StoreAppStateNames.Modal]: StoreAppModal;
  [StoreAppStateNames.Theme]: StoreAppTheme;
  [StoreAppStateNames.Hints]: StoreAppHintsConfig;
}
export const StoreAppStateDefault: StoreAppState = {
  [StoreAppStateNames.Loading]: false,
  [StoreAppStateNames.Notification]: StoreAppNotificationDefault,
  [StoreAppStateNames.Modal]: StoreAppModalDefault,
  [StoreAppStateNames.Theme]: { ...StoreAppThemeDefault },
  [StoreAppStateNames.Hints]: {
    queue: [],
    shown: { ...StoreAppHintsDataDefault },
  },
};

/**
 * Store Actions
 */
export const APP_LOADING_START = "loading/start";
export type StoreActAppLoadingStart = StoreActBase<typeof APP_LOADING_START>;
export const APP_LOADING_STOP = "loading/stop";
export type StoreActAppLoadingStop = StoreActBase<typeof APP_LOADING_STOP>;

export const APP_NOTIFICATION_SET = "notification/set";
export type StoreActAppNotificationSet = StoreActBase<
  typeof APP_NOTIFICATION_SET,
  StoreAppNotification
>;
export const APP_NOTIFICATION_CLEAR = "notification/clear";
export type StoreActAppNotificationClear = StoreActBase<typeof APP_NOTIFICATION_CLEAR>;

export const APP_MODAL_OPEN = "modal/open";
export type StoreActAppModalOpen = StoreActBase<typeof APP_MODAL_OPEN, StoreAppModal>;
export const APP_MODAL_CLOSE = "modal/close";
export type StoreActAppModalClose = StoreActBase<typeof APP_MODAL_CLOSE>;

export const APP_THEME_CHANGE = "theme/change";
export type StoreActAppThemeChange = StoreActBase<
  typeof APP_THEME_CHANGE,
  StoreAppThemeConfig
>;
export const APP_THEME_RESET = "theme/reset";
export type StoreActAppThemeReset = StoreActBase<typeof APP_THEME_RESET>;

export const APP_HINTS_UPDATE = "hints/update";
export type StoreActAppHintsUpdate = StoreActBase<
  typeof APP_HINTS_UPDATE,
  StoreAppHintsConfig
>;
export const APP_HINTS_SHOWN = "hints/shown";
export type StoreActAppHintsShown = StoreActBase<
  typeof APP_HINTS_SHOWN,
  { type: StoreAppHintType; isShown: boolean }
>;
export const APP_HINTS_QUEUE_UPDATE = "hints/queue/update";
export type StoreActAppHintsQueueUpdate = StoreActBase<
  typeof APP_HINTS_QUEUE_UPDATE,
  HintProps[]
>;

export type StoreAppActs =
  | StoreActAppLoadingStart
  | StoreActAppLoadingStop
  | StoreActAppNotificationSet
  | StoreActAppNotificationClear
  | StoreActAppModalOpen
  | StoreActAppModalClose
  | StoreActAppThemeChange
  | StoreActAppThemeReset
  | StoreActAppHintsShown
  | StoreActAppHintsQueueUpdate
  | StoreActAppHintsUpdate;
