import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserLocale } from "../../store/user";

export const enum AppLanguages {
  EN = "en",
  UA = "ua",
}

export const enum AppTranslationText {
  AuthLoading = "Auth.Loading",

  AuthLoginTitle = "Auth.Login.Title",
  AuthLoginSubtitle = "Auth.Login.Subtitle",
  AuthLoginLoginButton = "Auth.Login.LoginButton",
  AuthLoginForgotPassword = "Auth.Login.ForgotPassword",
  AuthLoginNewProfile = "Auth.Login.NewProfile",
  AuthLoginError = "Auth.Login.Error",

  AuthRecoveryTitle = "Auth.Recovery.Title",
  AuthRecoverySubtitle = "Auth.Recovery.Subtitle",
  AuthRecoverySentTitle = "Auth.Recovery.Sent.Title",
  AuthRecoverySentSubtitle = "Auth.Recovery.Sent.Subtitle",
  AuthRecoveryCompletionTitle = "Auth.Recovery.Completion.Title",
  AuthRecoveryCompletionSubtitle = "Auth.Recovery.Completion.Subtitle",
  AuthRecoveryErrorsInvalidEmail = "Auth.Recovery.Errors.InvalidEmail",

  AuthRegisterTitle = "Auth.Register.Title",
  AuthRegisterSubtitle = "Auth.Register.Subtitle",
  AuthRegisterAlreadyHaveProfile = "Auth.Register.AlreadyHaveProfile",
  AuthRegisterRegisterButton = "Auth.Register.RegisterButton",
  AuthRegisterCompletionTitle = "Auth.Register.Completion.Title",
  AuthRegisterCompletionSubtitle = "Auth.Register.Completion.Subtitle",
  AuthRegisterErrorsInvalidPassword = "Auth.Register.Errors.InvalidPassword",

  HeaderMenuMyProfile = "Header.Menu.MyProfile",
  HeaderMenuAppearance = "Header.Menu.Appearance",
  HeaderMenuLogOut = "Header.Menu.LogOut",
  HeaderSearchQuery = "Header.Search.Query",
  HeaderSearchEmpty = "Header.Search.Empty",
  HeaderSearchLoading = "Header.Search.Loading",

  IdeasEmpty = "Ideas.Empty",
  IdeasGoBack = "Ideas.GoBack",

  IdeasTabsMyIdeas = "Ideas.Tabs.MyIdeas",
  IdeasTabsInbox = "Ideas.Tabs.Inbox",
  IdeasTabsRequests = "Ideas.Tabs.Requests",
  IdeasTabsOnReview = "Ideas.Tabs.OnReview",
  IdeasTabsLoading = "Ideas.Tabs.Loading",

  IdeasMyIdeasAddIdea = "Ideas.MyIdeas.AddIdea",
  IdeasMyIdeasUseCases = "Ideas.MyIdeas.UseCases",

  IdeasMyIdeasErrorRollback = "Ideas.MyIdeas.Error.Rollback",

  IdeasInboxNewIdeas = "Ideas.Inbox.NewIdeas",
  IdeasInboxActionReview = "Ideas.Inbox.Action.Review",
  IdeasInboxActionEdit = "Ideas.Inbox.Action.Edit",
  IdeasInboxActionViewInitialRequest = "Ideas.Inbox.Action.ViewInitialRequest",
  IdeasInboxInProgress = "Ideas.Inbox.InProgress",

  IdeasRequestsEmpty = "Ideas.Requests.Empty",
  IdeasRequestsMakeNew = "Ideas.Requests.MakeNew",
  IdeasRequestsMakeIdea = "Ideas.Requests.MakeIdea",

  IdeasRequestsNewTitle = "Ideas.Requests.New.Title",
  IdeasRequestsNewNoteSubject = "Ideas.Requests.New.Note.Subject",
  IdeasRequestsNewNoteDescription = "Ideas.Requests.New.Note.Description",
  IdeasRequestsNewNoteScope = "Ideas.Requests.New.Note.Scope",
  IdeasRequestsNewNoteDeadline = "Ideas.Requests.New.Note.Deadline",
  IdeasRequestsNewPlaceholderSubject = "Ideas.Requests.New.Placeholder.Subject",
  IdeasRequestsNewPlaceholderDescription = "Ideas.Requests.New.Placeholder.Description",
  IdeasRequestsNewPlaceholderScope = "Ideas.Requests.New.Placeholder.Scope",
  IdeasRequestsNewPlaceholderDeadline = "Ideas.Requests.New.Placeholder.Deadline",
  IdeasRequestsErrorNew = "Ideas.Requests.Error.New",
  IdeasRequestsErrorEdit = "Ideas.Requests.Error.Edit",

  IdeasUseCaseProvider = "Ideas.UseCase.Provider",
  IdeasUseCaseIndustryFocus = "Ideas.UseCase.IndustryFocus",
  IdeasUseCaseFunction = "Ideas.UseCase.Function",
  IdeasUseCaseCustomerUse = "Ideas.UseCase.CustomerUse",
  IdeasUseCaseNewIdea = "Ideas.UseCase.NewIdea",
  IdeasUseCaseEmpty = "Ideas.UseCase.Empty",

  IdeasDecisions = "Ideas.Decisions",
  IdeasStages = "Ideas.Stages",
  IdeasStatuses = "Ideas.Statuses",

  IdeasStagesReturned = "Ideas.Stages.returned",

  IdeasManageCardsTitles = "Ideas.Manage.Cards.Titles",
  IdeasManageCardsDetails = "Ideas.Manage.Cards.Details",

  IdeasManageAttachmentsTitle = "Ideas.Manage.Attachments.Title",
  IdeasManageAttachmentsShow = "Ideas.Manage.Attachments.Show",
  IdeasManageAttachmentsHide = "Ideas.Manage.Attachments.Hide",
  IdeasManageAttachmentsGallery = "Ideas.Manage.Attachments.Gallery",
  IdeasManageAttachmentsFiles = "Ideas.Manage.Attachments.Files",
  IdeasManageAttachmentsVideo = "Ideas.Manage.Attachments.Video",
  IdeasManageAttachmentsEmpty = "Ideas.Manage.Attachments.Empty",

  IdeasManageSetup = "Ideas.Manage.Setup",
  IdeasManageScrollDown = "Ideas.Manage.ScrollDown",

  IdeasManageStagesOpinionTitle = "Ideas.Manage.Stages.Opinion.Title",
  IdeasManageStagesOpinionPlaceholderOpinion = "Ideas.Manage.Stages.Opinion.Placeholder.Opinion",
  IdeasManageStagesOpinionPlaceholderDeclineComment = "Ideas.Manage.Stages.Opinion.Placeholder.DeclineComment",
  IdeasManageStagesOpinionSendBack = "Ideas.Manage.Stages.Opinion.SendBack",

  IdeasManageStagesTeamTitle = "Ideas.Manage.Stages.Team.Title",
  IdeasManageStagesTeamAdd = "Ideas.Manage.Stages.Team.Add",
  IdeasManageStagesTeamPlaceholderRole = "Ideas.Manage.Stages.Team.Placeholder.Role",
  IdeasManageStagesTeamPlaceholderUser = "Ideas.Manage.Stages.Team.Placeholder.User",
  IdeasManageStagesTeamCompletion = "Ideas.Manage.Stages.Team.Completion",

  IdeasManageStagesDeadlineTitle = "Ideas.Manage.Stages.Deadline.Title",
  IdeasManageStagesDeadlinePlaceholder = "Ideas.Manage.Stages.Deadline.Placeholder",
  IdeasManageStagesDeadlineCompletion = "Ideas.Manage.Stages.Deadline.Completion",

  IdeasManageStagesPlatformsTitle = "Ideas.Manage.Stages.Platforms.Title",
  IdeasManageStagesPlatformsEmpty = "Ideas.Manage.Stages.Platforms.Empty",
  IdeasManageStagesPlatformsCompletion = "Ideas.Manage.Stages.Platforms.Completion",

  IdeasManageStagesTestingTitle = "Ideas.Manage.Stages.Testing.Title",
  IdeasManageStagesTestingPlaceholder = "Ideas.Manage.Stages.Testing.Placeholder",
  IdeasManageStagesTestingConfirm = "Ideas.Manage.Stages.Testing.Confirm",

  IdeasManageStagesResultTitle = "Ideas.Manage.Stages.Result.Title",
  IdeasManageStagesResultPlaceholderSelect = "Ideas.Manage.Stages.Result.Placeholder.Select",
  IdeasManageStagesResultPlaceholderCommentary = "Ideas.Manage.Stages.Result.Placeholder.Commentary",
  IdeasManageStagesResultConfirm = "Ideas.Manage.Stages.Result.Confirm",

  IdeasManageError = "Ideas.Manage.Error",

  IdeasMenuEdit = "Ideas.Menu.Edit",
  IdeasMenuDelete = "Ideas.Menu.Delete",
  IdeasMenuPreview = "Ideas.Menu.Preview",

  IdeasQueriesLoading = "Ideas.Queries.Loading",
  IdeasQueriesEmpty = "Ideas.Queries.Empty",

  EditorBack = "Editor.Back",
  EditorPageTitle = "Editor.PageTitle",
  EditorPlaceholderTitle = "Editor.Placeholder.Title",
  EditorPlaceholderContent = "Editor.Placeholder.Content",
  EditorFulfillmentRequires = "Editor.FulfillmentRequires",

  EditorAttachmentInsert = "Editor.Attachment.Insert",
  EditorAttachmentInsertLink = "Editor.Attachment.InsertLink",

  EditorAttachmentImagesTitle = "Editor.Attachment.Images.Title",
  EditorAttachmentImagesPlaceholder = "Editor.Attachment.Images.Placeholder",

  EditorAttachmentVideoTitle = "Editor.Attachment.Video.Title",
  EditorAttachmentVideoPlaceholder = "Editor.Attachment.Video.Placeholder",

  EditorAttachmentFilesTitle = "Editor.Attachment.Files.Title",
  EditorAttachmentFilesPlaceholder = "Editor.Attachment.Files.Placeholder",

  EditorAttachmentUseCaseTitle = "Editor.Attachment.UseCase.Title",
  EditorAttachmentUseCaseDescription = "Editor.Attachment.UseCase.Description",

  EditorAttachmentOnDrag = "Editor.Attachment.OnDrag",
  EditorAttachmentOnUploading = "Editor.Attachment.OnUploading",

  EditorAttachmentQuestionsOpenAnswer = "Editor.Attachment.Questions.OpenAnswer",

  EditorGradeTitle = "Editor.Grade.Title",
  EditorGradePathTitle = "Editor.Grade.Path.Title",
  EditorGradePathPlaceholder = "Editor.Grade.Path.Placeholder",
  EditorGradeScaleTitle = "Editor.Grade.Scale.Title",
  EditorGradeScaleSubtitle = "Editor.Grade.Scale.Subtitle",
  EditorGradeElaborationTitle = "Editor.Grade.Elaboration.Title",
  EditorGradeElaborationSubtitle = "Editor.Grade.Elaboration.Subtitle",
  EditorGradeComplexityTitle = "Editor.Grade.Complexity.Title",
  EditorGradeComplexitySubtitle = "Editor.Grade.Complexity.Subtitle",

  EditorSaving = "Editor.Saving",

  EditorErrorFetchIdea = "Editor.Error.Fetch.Idea",
  EditorErrorFetchUseCase = "Editor.Error.Fetch.UseCase",
  EditorErrorFetchQuestion = "Editor.Error.Fetch.Question",

  EditorErrorPublish = "Editor.Error.Publish",
  EditorErrorAnswer = "Editor.Error.Answer",
  EditorErrorCreation = "Editor.Error.Creation",
  EditorErrorUpdate = "Editor.Error.Update",
  EditorErrorUpload = "Editor.Error.Upload",
  EditorErrorVideo = "Editor.Error.Video",

  EditorErrorFilesAtOnceLimit = "Editor.Error.Files.AtOnceLimit",
  EditorErrorFilesDuplicate = "Editor.Error.Files.Duplicate",
  EditorErrorFilesFormat = "Editor.Error.Files.Format",
  EditorErrorFilesSize = "Editor.Error.Files.Size",

  SettingsPageTitle = "Settings.PageTitle",

  SettingsMyProfileTab = "Settings.MyProfile.Tab",
  SettingsMyProfileTitle = "Settings.MyProfile.Title",
  SettingsMyProfilePasswordTitle = "Settings.MyProfile.PasswordTitle",
  SettingsMyProfileError = "Settings.MyProfile.Error",

  SettingsMyProfileAvatarChangeTitle = "Settings.MyProfile.Avatar.Change.Title",
  SettingsMyProfileAvatarChangeSubtitle = "Settings.MyProfile.Avatar.Change.Subtitle",

  SettingsMyProfileAvatarDeleteTitle = "Settings.MyProfile.Avatar.Delete.Title",
  SettingsMyProfileAvatarDeleteSubtitle = "Settings.MyProfile.Avatar.Delete.Subtitle",

  SettingsMyProfileAvatarError = "Settings.MyProfile.Avatar.Error",

  SettingsMyProfileNoteName = "Settings.MyProfile.Note.Name",
  SettingsMyProfileNoteEmail = "Settings.MyProfile.Note.Email",
  SettingsMyProfileNoteLanguage = "Settings.MyProfile.Note.Language",
  SettingsMyProfileNotePasswordCurrent = "Settings.MyProfile.Note.Password.Current",
  SettingsMyProfileNotePasswordNew = "Settings.MyProfile.Note.Password.New",
  SettingsMyProfileNotePasswordNewConfirm = "Settings.MyProfile.Note.Password.NewConfirm",

  SettingsMyProfilePlaceholderName = "Settings.MyProfile.Placeholder.Name",
  SettingsMyProfilePlaceholderEmail = "Settings.MyProfile.Placeholder.Email",
  SettingsMyProfilePlaceholderLanguage = "Settings.MyProfile.Note.Language",
  SettingsMyProfilePlaceholderPasswordCurrent = "Settings.MyProfile.Placeholder.Password.Current",
  SettingsMyProfilePlaceholderPasswordNew = "Settings.MyProfile.Placeholder.Password.New",
  SettingsMyProfilePlaceholderPasswordNewConfirm = "Settings.MyProfile.Placeholder.Password.NewConfirm",

  SettingsAppearanceTab = "Settings.Appearance.Tab",
  SettingsAppearanceTitle = "Settings.Appearance.Title",
  SettingsAppearanceSchema = "Settings.Appearance.Schema",
  SettingsAppearanceAccent = "Settings.Appearance.Accent",
  SettingsAppearanceTheme = "Settings.Appearance.Theme",
  SettingsAppearanceBackground = "Settings.Appearance.Background",
  SettingsAppearanceAccentTypeDefault = "Settings.Appearance.AccentType.Default",
  SettingsAppearanceAccentTypeRM = "Settings.Appearance.AccentType.RM",

  SettingsCSTab = "Settings.CS.Tab",
  SettingsCSTitle = "Settings.CS.Title",
  SettingsCSAdd = "Settings.CS.Add",
  SettingsCSRegistered = "Settings.CS.Registered",
  SettingsCSEmpty = "Settings.CS.Empty",

  SettingsCSEditTitle = "Settings.CS.EditTitle",

  SettingsCSRemoveTitle = "Settings.CS.Remove.Title",
  SettingsCSRemoveSubtitle = "Settings.CS.Remove.Subtitle",

  SettingsCSNoteName = "Settings.CS.Note.Name",
  SettingsCSNoteEmail = "Settings.CS.Note.Email",
  SettingsCSNoteRole = "Settings.CS.Note.Role",

  SettingsCSPlaceholderName = "Settings.CS.Placeholder.Name",
  SettingsCSPlaceholderEmail = "Settings.CS.Placeholder.Email",
  SettingsCSPlaceholderRole = "Settings.CS.Placeholder.Role",

  SettingsCSRoles = "Settings.CS.Roles",

  SettingsCSErrorUpdate = "Settings.CS.Error.Update",
  SettingsCSErrorCreation = "Settings.CS.Error.Creation",
  SettingsCSErrorDeletion = "Settings.CS.Error.Deletion",

  SettingsSharingTab = "Settings.Sharing.Tab",
  SettingsSharingTitle = "Settings.Sharing.Title",
  SettingsSharingSubtitle = "Settings.Sharing.Subtitle",
  SettingsSharingCopy = "Settings.Sharing.Copy",
  SettingsSharingCopied = "Settings.Sharing.Copied",

  InputPlaceholderEmail = "Input.Placeholder.Email",
  InputPlaceholderPassword = "Input.Placeholder.Password",
  InputPlaceholderPasswordConfirm = "Input.Placeholder.PasswordConfirm",

  DateFormatted = "Date.Formatted",
  DateMonths = "Date.Months",

  HintsText = "Hints.Text",
  HintsButtonOK = "Hints.Button.OK",

  CommonOK = "Common.OK",
  CommonSend = "Common.Send",
  CommonSubmit = "Common.Submit",
  CommonCancel = "Common.Cancel",
  CommonClose = "Common.Close",
  CommonDetails = "Common.Details",
  CommonBack = "Common.Back",
  CommonSave = "Common.Save",
  CommonChange = "Common.Change",
  CommonDelete = "Common.Delete",

  CommonByteSizes = "Common.ByteSizes",

  CommonCreator = "Common.Creator",

  CommonErrorDataCount = "Common.Error.DataCount",
  CommonErrorData = "Common.Error.Data",
  CommonErrorDataDeletion = "Common.Error.DataDeletion",
  CommonErrorUserMe = "Common.Error.UserMe",
  CommonErrorResponse = "Common.Error.Response",
  CommonErrorUnknown = "Common.Error.Unknown",
  CommonErrorSession = "Common.Error.Session",
  CommonErrorForm = "Common.Error.Form",

  CommonNotificationUnknown = "Common.Notification.Unknown",
  CommonNotificationELogin = "Common.Notification.ELogin",
  CommonNotificationEServer = "Common.Notification.EServer",
  CommonNotificationEForm = "Common.Notification.EForm",
  CommonNotificationEApp = "Common.Notification.EApp",
  CommonNotificationSServerResponse = "Common.Notification.SServerResponse",
}

export interface AppLanguageOption {
  label: string;
  value: AppLanguages;
}

export const AppLanguageOptions: AppLanguageOption[] = [
  {
    label: "English",
    value: AppLanguages.EN,
  },
  {
    label: "Українська",
    value: AppLanguages.UA,
  },
];

export const AppLanguageMap2Option: Record<AppLanguages, AppLanguageOption> = {
  [AppLanguages.EN]: AppLanguageOptions[0],
  [AppLanguages.UA]: AppLanguageOptions[1],
};
export const AppLanguageValidate = (lang: AppLanguages): AppLanguages => {
  const _valids: AppLanguages[] = [AppLanguages.EN, AppLanguages.UA];

  let _l: AppLanguages = lang;
  let isValid = false;

  if (lang?.length) {
    for (const al of _valids) {
      if (_l?.toLowerCase()?.includes(al?.toLowerCase())) {
        isValid = true;
        break;
      }
    }
  }

  return isValid ? lang : AppLanguages.EN;
};
export const AppLanguageGetOption = (lang: AppLanguages): AppLanguageOption =>
  AppLanguageMap2Option[AppLanguageValidate(lang)];

const useAppTranslation = (): [
  t: (
    text: AppTranslationText,
    options?: { [key: string]: any; returnObjects?: boolean } | string
  ) => string,
  changeLanguage: (lang: AppLanguages) => void,
  currentLanguage: AppLanguages,
  getLocaleText: (obj: any | undefined | null, fieldName: string) => string
] => {
  const locale = useSelector(selectUserLocale);
  const { t, i18n } = useTranslation();

  const changeLanguage = useCallback(
    (lang: AppLanguages) => i18n.changeLanguage(lang),
    [i18n]
  );

  const getLocaleText = useCallback(
    (obj: any | undefined | null, fieldName: string): string => {
      if (!obj) {
        return "";
      }

      const lang = i18n.language.toUpperCase();
      let _str = obj[`${fieldName}${lang}`];

      if (!_str?.length) {
        const _strEN = obj[`${fieldName}EN`];
        if (_strEN?.length) {
          _str = _strEN;
        } else {
          _str = obj[fieldName];
        }
      }

      return _str?.length ? _str : "UNKNOWN FIELD NAME";
    },
    [i18n.language]
  );

  useEffect(() => {
    if (locale?.length && locale !== i18n.language) {
      changeLanguage(locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, i18n]);

  return [t, changeLanguage, i18n.language as AppLanguages, getLocaleText];
};

export default useAppTranslation;
